import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SequenceFilterDrawer from "components/drawers/SequenceFilterDrawer";
import { useDispatch, useSelector } from "react-redux";
import { getSequenceByFilter } from "store/selectors/sequence";
import { loadAllSequencesAsync } from "store/slices/SequenceSlice";
import { setSearchString } from "store/slices/sequenceFilterSlice";
import Grid from "@mui/system/Unstable_Grid";
import SequenceCard from "components/cards/SequenceCard";
import { SequenceDto } from "api";
import SequenceInfoView from "views/CardViews/SequenceInfoView";
import { StdView } from "views/OtherViews/StdView";

export default function SequenceView() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState<SequenceDto>();

  const sequenceList = useSelector(getSequenceByFilter);

  useEffect(() => {
    dispatch<any>(loadAllSequencesAsync());
  }, [dispatch]);

  useEffect(() => {
    const sequence = sequenceList.find((s) => s.sequenceId == selectedSequence?.sequenceId)
    if(sequence)
      setSelectedSequence(sequence)
  }, [sequenceList]);


  const onSearchEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchString(e.target.value));
  };
  function handleClose(): void {
    setOpen(false);
  }

  function handleClick(sequence: SequenceDto): void {
    setSelectedSequence(sequence);
    setOpen(true);
  }

  return (
    <StdView title={`Sekvenser (${sequenceList ? sequenceList.length : ""})`}>
      <Stack direction={"column"} spacing={3}>
        <Typography variant="h3" fontWeight={400}>
          Här visas alla delade sekvenser. Låt dig inspireras!
        </Typography>
        <Stack direction={"row"} spacing={1}>
          <OutlinedInput
            sx={{ width: "100%", borderRadius: "50px", height: "46px" }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            placeholder={"Sök sekvens"}
            onChange={onSearchEvent}
          />
          <SequenceFilterDrawer />
        </Stack>
        <Box>
          <Grid container spacing={{ xs: 1, sm: 3 }}>
            {sequenceList ? (
              sequenceList.map((sequence, index) => (
                <Grid key={index} xs={12} sm={6} md={4} lg={3}>
                  <SequenceCard data={sequence} handleClick={handleClick} />
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Grid>
        </Box>
      </Stack>

      <Drawer
        open={open}
        anchor={"right"}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#F5F4F0",
          },
        }}
      >
        <SequenceInfoView
          sequence={selectedSequence}
          handleClose={handleClose}
        />
      </Drawer>
    </StdView>
  );
}
