import { Button, Grid, alpha } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IInspiration } from "utils/contentfulModel";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

export interface InspirationCardProps {
  data: IInspiration | null;
}
export default function InspirationCard({ data }: InspirationCardProps) {
  const dispatch = useDispatch();

  return (
    <Card
      sx={{
        borderRadius: "15px",
        background: "#43594C",
        height: "264px",
        position: "relative",
        pl: { xs: "6%", sm: 0 },
        pr: { xs: "6%", sm: 0 },
      }}
    >
      <Grid sx={{ mt: "41px", mb: "21px" }}>
        <Grid sx={{ pl: "12px", pr: "12px", pt: 0, pb: "0px" }}>
          <Typography
            variant="h3"
            gutterBottom
            sx={{ color: "#fff", textAlign: "center", mb: "21px" }}
          >
            {data?.title}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "#fff",
              textAlign: "center",
              textShadow: "0px 3px 6px #00000029",
              lineHeight: "22px",
            }}
          >
            {data?.subTitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <CardActions sx={{ p: 0 }}>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid sx={{ position: "absolute", bottom: "31px" }}>
              <Button
                component={Link}
                to={`/inspiration/${data?.sys.id}`}
                variant="contained"
                sx={{
                  backgroundColor: "#EDEAE4",
                  color: "#43594C",
                  "&:hover": {
                    color: "#fff",
                    backgroundColor: alpha("#EDEAE4", 0.5),
                  },
                }}
                endIcon={<ArrowCircleRightOutlinedIcon fontSize="inherit" />}
              >
                Läs mer
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Grid>
    </Card>
  );
}
