import * as React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useState } from "react";
import { PositionBaseDto, PositionDto } from "api";

import PositionAvatar from "components/avatars/PositionAvatar";
import DrawerContent from "components/drawers/DrawerContent";
import DrawerHeader from "components/drawers/DrawerHeader";
import PositionsInfoView from "views/CardViews/PositionsInfoView";
import { useDispatch } from "react-redux";
import { addSequenceItem } from "store/slices/SequenceFormSlice";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "15px",
  backgroundColor: "#FFF",
  mb: 1,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

interface PrePositionsProps {
  title: string;
  children?: React.ReactNode;
  data?: PositionDto | null;
  refreshData?: boolean;
  addToSequence?: boolean;
}

export const PrePositionsAccordion = (
  props: PrePositionsProps
): JSX.Element => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [selectedPosition, setSelectedPosition] = useState<PositionDto>();

  const handleChange = () => {
    setExpanded(!expanded);
  };

  function handleClose(): void {
    setOpen(false);
  }

  const handleSelectPosition = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    position: PositionDto
  ) => {
    if (!position.private) {
      setSelectedPosition(position);
      setOpen(true);
    } else e.preventDefault();
  };

  function getPositionText(position: PositionBaseDto): string {
    var text = position.swedish!;
    // text += position.private ? " - " + position.sanskrit : "";
    return text;
  }

  const handleAddToSequence = () => {
    console.log("Add to sequence");
    dispatch(addSequenceItem(selectedPosition!));
    setOpen(false);
  };

  return (
    <>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}>
          <Typography variant="h6" component={"h3"}>
            {props.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          <List dense>
            {props.data?.prePose1 && (
              <ListItem
                onClick={(e) => handleSelectPosition(e, props.data?.prePose1!)}
                sx={{ cursor: "pointer" }}
              >
                <ListItemIcon>
                  <PositionAvatar
                    images={props.data?.prePose1?.positionImages!}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={getPositionText(props.data?.prePose1!)}
                />
              </ListItem>
            )}
            {props.data?.prePose2 && (
              <ListItem
                onClick={(e) => handleSelectPosition(e, props.data?.prePose2!)}
                sx={{ cursor: "pointer" }}
              >
                <ListItemIcon>
                  <PositionAvatar
                    images={props.data?.prePose2?.positionImages!}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={getPositionText(props.data?.prePose2!)}
                />
              </ListItem>
            )}
            {props.data?.prePose3 && (
              <ListItem
                onClick={(e) => handleSelectPosition(e, props.data?.prePose3!)}
                sx={{ cursor: "pointer" }}
              >
                <ListItemIcon>
                  <PositionAvatar
                    images={props.data?.prePose3?.positionImages!}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={getPositionText(props.data?.prePose3!)}
                />
              </ListItem>
            )}
            {!props.data?.prePose1 &&
              !props.data?.prePose2 &&
              !props.data?.prePose3 && (
                <ListItem>
                  <ListItemText
                    primary={"Positionen har inga föregående positioner"}
                  />
                </ListItem>
              )}
          </List>
        </AccordionDetails>
      </Accordion>
      <Drawer
        open={open}
        anchor={"right"}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#FFF",
          },
        }}
      >
        {props.addToSequence === undefined && <DrawerHeader handleClose={handleClose} />}
        {props.addToSequence !== undefined && props.addToSequence && (
          <DrawerHeader
            handleClose={handleClose}
            toolbar={
              <Button
                onClick={handleAddToSequence}
                variant="contained"
                color="secondary"
              >
                Lägg till i sekvens
              </Button>
            }
          />
        )}

        <DrawerContent>
          <PositionsInfoView
            position={selectedPosition}
            refreshData={props.refreshData}
            addToSequence={props.addToSequence}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
};
