import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { SequenceItemDto } from "api";
import React from "react";
import PauseIcon from "@mui/icons-material/Pause";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch } from "react-redux";
import { addSequenceNote, addSequencePause } from "store/slices/SequenceFormSlice";
import AssignmentIcon from "@mui/icons-material/Assignment";

export type SequenceNoteListItemProps = {};

const SequenceNoteListItem = ({}: SequenceNoteListItemProps) => {
  const dispatch = useDispatch();

  return (
    <ListItem
      sx={{
        background: "#fff",
        borderRadius: "10px",
        boxShadow: 1,
        minHeight: "53px",
        "&.MuiListItem-root": { py: "5px" },
      }}
      secondaryAction={
        <IconButton onClick={() => dispatch(addSequenceNote(""))}>
          <AddCircleIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar>
        <AssignmentIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={"Anteckning"} />
    </ListItem>
  );
};

export default SequenceNoteListItem;
