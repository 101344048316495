import { Alert, Stack, TextField } from "@mui/material";
import {
  authStateSelector,
  currentUser,
  registerAsync,
} from "store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import type { RegisterRequest } from "api";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import PasswordField from "./PasswordField";
import isEmailValidator from "validator/lib/isEmail";
import { useNavigate } from "react-router-dom";
import { registerUserAsync } from "store/slices/paymentSlice";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .matches(/^(?!soulwise\b)/i, "soulwise är ett reserverat ord")
    .required("Förnamn krävs"),
  lastName: yup
    .string()
    .matches(/^(?!soulwise\b)/i, "soulwise är ett reserverat ord")
    .required("Efternamn krävs"),
  email: yup
    .string()
    .email("Ange en giltlig e-postadress")
    .required("E-postadress krävs")
    .test("is-valid", "Ange en giltlig e-postadress", (value) =>
      value
        ? isEmailValidator(value)
        : new yup.ValidationError("Ange en giltlig e-postadress")
    ),
  emailConfirm: yup
    .string()
    .test("email", "E-postadressen matchar inte", function (emailConfirmation) {
      return emailConfirmation === this.parent.email;
    }),
  password: yup
    .string()
    .required("Ange ett giltligt lösenord")
    .matches(
      /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Lösenordet måste innehålla minst 8 karaktärer, en versal, ett nummer och ett specialtecken"
    ),
});

interface RegisterFormProps {
  onUserCreated(): void;
}
export default function RegisterForm({onUserCreated}:RegisterFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticating, hasRegError, regError } =
    useSelector(authStateSelector);

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={true}
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        emailConfirm: "",
        password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        try {
          var request = {
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            email: values.email,
          } as RegisterRequest;
          const result = await dispatch<any>(registerUserAsync(request));
          if (result) {
            onUserCreated();
          }
        } catch (error) {
          console.log(error);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values, errors, handleChange, touched }) => (
        <Form noValidate autoComplete="off">
          <Stack spacing={2}>
            {hasRegError && (
              <Alert severity="error" variant="outlined">
                {regError}
              </Alert>
            )}
            <TextField
              sx={{ width: { xs: "100%", sm: "auto" } }}
              required
              id="firstName"
              name="firstName"
              label="Förnamn"
              variant="outlined"
              value={values.firstName}
              onChange={handleChange}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              fullWidth
              size="small"
            />

            <TextField
              sx={{ width: { xs: "100%", sm: "auto" } }}
              required
              id="lastName"
              name="lastName"
              label="Efternamn"
              variant="outlined"
              value={values.lastName}
              onChange={handleChange}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              size="small"
            />
            <TextField
              sx={{ width: { xs: "100%", sm: "auto" } }}
              required
              id="email"
              name="email"
              label="E-postadress"
              variant="outlined"
              value={values.email}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              size="small"
            />
            <TextField
              sx={{ width: { xs: "100%", sm: "auto" } }}
              required
              id="emailConfirm"
              name="emailConfirm"
              label="Bekräfta e-postadress"
              variant="outlined"
              value={values.emailConfirm}
              onChange={handleChange}
              error={touched.emailConfirm && Boolean(errors.emailConfirm)}
              helperText={touched.emailConfirm && errors.emailConfirm}
              size="small"
            />
            <PasswordField
              required
              fullWidth
              name="password"
              label="Lösenord"
              id="password"
              disabled={isAuthenticating}
              autoComplete="current-password"
              size="small"
            />
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={isAuthenticating}
              disabled={isSubmitting}
            >
              Registrera
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
