export interface ApiErrorBody {
  error?: string;
}

export const getStatusText = (status: number) => {
  let statusText: string = "";

  switch (status) {
    case 1:
      statusText = "Utkast";
      break;
    case 2:
      statusText = "Publicerad";
      break;
    case 3:
      statusText = "Borttagen";
      break;
  }

  return statusText;
};

export const levelIds = [1, 2, 3];

export const getLevelTitle = (levelId: number) => {
  let level: string = "";

  switch (levelId) {
    case 1:
      level = "Nybörjare";
      break;
    case 2:
      level = "Medel";
      break;
    case 3:
      level = "Avancerad";
      break;
  }

  return level;
};

export const yogaFormIds = [
  1, 2, 3, 4, 5, 6, 16, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16
];

export const getYogaFormText = (yogaFormId: number): string => {
  let yogaForm: string = "";

  switch (yogaFormId) {
    case 1:
      yogaForm = "Ashtanga";
      break;
    case 2:
      yogaForm = "Barn";
      break;
    case 3:
      yogaForm = "Bikram";
      break;
    case 4:
      yogaForm = "Gravid";
      break;
    case 5:
      yogaForm = "Hatha";
      break;
    case 6:
      yogaForm = "Hormon";
      break;
    case 7:
      yogaForm = "Kundalini";
      break;
    case 8:
      yogaForm = "Lyengar";
      break;
    case 9:
      yogaForm = "Medical";
      break;
    case 10:
      yogaForm = "Power";
      break;
    case 11:
      yogaForm = "Restorative";
      break;
    case 12:
      yogaForm = "Satyananda";
      break;
    case 13:
      yogaForm = "Vinyasa";
      break;
    case 14:
      yogaForm = "Virya";
      break;
    case 15:
      yogaForm = "Yin";
      break;
    case 16:
      yogaForm = "Hot";
      break;
  }

  return yogaForm;
};

export const mainTypesIds = [6, 10, 2, 7, 1, 3, 8, 9, 4, 5, 11, 12];

export const getMainTypesText = (mainTypeId: number): string => {
  let mainTypeText: string = "";

  switch (mainTypeId) {
    case 1:
      mainTypeText = "Framåtfällning";
      break;
    case 2:
      mainTypeText = "Bakåtböjning";
      break;
    case 3:
      mainTypeText = "Inversion";
      break;
    case 4:
      mainTypeText = "Sittande";
      break;
    case 5:
      mainTypeText = "Stående";
      break;
    case 6:
      mainTypeText = "Armbalans";
      break;
    case 7:
      mainTypeText = "Balans";
      break;
    case 8:
      mainTypeText = "Liggande";
      break;
    case 9:
      mainTypeText = "Uppvärmning";
      break;
    case 10:
      mainTypeText = "Avslutning";
      break;
    case 11:
      mainTypeText = "Twist";
      break;
    case 12:
      mainTypeText = "Yin";
      break;
  }

  return mainTypeText;
};

export const reorder = <T>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
