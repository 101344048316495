import {
  Card,
  CardContent,
  CardActions,
  Button,
  CardMedia,
  Grid,
  CardActionArea,
  FormControlLabel,
  Switch,
  Box,
} from "@mui/material";
import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  PositionDto,
  SequenceDto,
  SequenceImageDto,
  SequenceItemDto,
} from "api";
import { useDispatch, useSelector } from "react-redux";
import { getMeAsync, authStateSelector } from "store/slices/authSlice";
import { getLevelTitle, getYogaFormText } from "utils/model";
import { setPrivate } from "store/slices/SequenceFormSlice";
import dayjs from "dayjs";

export interface ReviewNewSequenceCardProps {
  sequence: SequenceDto;
}

export default function ViewSequenceCard({
  sequence,
}: ReviewNewSequenceCardProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch<any>(getMeAsync());
  }, [dispatch]);

  const user = useSelector(authStateSelector).currentUser;

  const sequenceimage = sequence.image!;
  const startPath = "..\\SequenceImages\\";
  const endPath = `${sequenceimage.path}`;
  const fullPath = startPath + endPath;

  return (
    sequence && (
      <Card
        elevation={0}
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "15px",
          boxShadow: "0px 1px 24px #0000000F",
          minHeight: "314px",
          maxWidth: "400px",
        }}
      >
        <Box
          sx={{
            p: "15px",
            pb: "0px",
          }}
        >
          <CardMedia
            component="img"
            image={`${fullPath}`}
            alt="sequence"
            title=""
            sx={{
              height: "177px",
              borderRadius: "7px",
            }}
          />
        </Box>
        <CardContent
          sx={{
            p: "15px",
            pb: "8px",
            pt: "8px",
          }}
        >
          <Typography sx={{ fontSize: "18px" }}>{sequence.title}</Typography>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  opacity: "50%",
                  fontSize: "13px",
                }}
              >
                {getLevelTitle(sequence.level!)} |{" "}
                {getYogaFormText(sequence.yogaForm!)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {sequence.sequenceItems!.length} positioner
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            pl: "15px",
            pb: "15px",
            pr: "15px",
            pt: "0px",
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "11px" }}>
                {user?.firstName} {user?.lastName}
              </Typography>
              <Typography
                sx={{
                  opacity: "50%",
                  fontSize: "11px",
                }}
              >
                {dayjs(sequence.modifiedDate!).format("YYYY-MM-DD")}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
              {/*<FormControlLabel
                control={
                  <Switch
                    onChange={(e) => dispatch(setPrivate(e.target.checked))}
                    checked={sequence.private}
                  />
                }
                label="Privat"
              />*/}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    )
  );
}
