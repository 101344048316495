
import { Button, Card, CardContent, CardHeader, Link, Typography } from '@mui/material'
import React from 'react'

const SubscriptionHistory = () => {
  return (
    <Card>
      <CardHeader title='Länkar'></CardHeader>
      <CardContent>
        <Link sx={{py:1, px:2, color:'#fff', backgroundColor:(theme) => theme.palette.primary.main, borderRadius:90}} target="_blank" rel="noopener" href={process.env.REACT_APP_STRIPE_CUSTOMERPORTAL} gutterBottom>Stripe Kundportal</Link>

        <Typography variant='body2' sx={{p:"5px", border:1, borderRadius:1, mt:"10px", borderColor:"#F0F0F0"}}>Här kan du se information om ditt abonnemang, se kvitton och ändra kortuppgifter.</Typography>
      </CardContent>
    </Card>
  )
}

export default SubscriptionHistory