import React, { useEffect, useState } from "react";
import { StdView } from "./StdView";
import { Navigate } from "react-router-dom";
import { Container, Stack } from "@mui/system";
import { Typography } from "@mui/material";
import { PaymentService } from "api";

export const PaymentView = () => {
  const [status, setStatus] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    const url = process.env.REACT_APP_SOULWISE_API_BASE + "/api/payment";

    PaymentService.getApiPaymentSessionStatus(sessionId!).then((sessionData) => {
      setStatus(sessionData.status!);
    })



    fetch(`${url}/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  if (status === "open") {
    return <Navigate to="/subscription?step=1" />;
  }

  if (status === "complete") {
    return (
      <StdView title={"Sammanställning"}>
        <Container maxWidth={"lg"}>
          <Stack>
            <Typography variant="h1">Tack för din prenumeration</Typography>
            <Typography variant="h1">Nu är det bara att börja använda Soulwise</Typography>
          </Stack>
        </Container>
      </StdView>
    );
  }

  return null;
};
