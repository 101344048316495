import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AccountService, ApiError, OpenAPI, ProductDto, RegisterRequest, UserDto } from "api";
import { AppThunk, RootState } from "store";
import { ApiErrorBody } from "utils/model";

export interface RegisterState {
    user: UserDto | null;
    product: ProductDto | null;
    priceId: number;
    loading: boolean;
    hasError: boolean;
    error?: string;
  }
  
  const initialState: RegisterState = {
    user: null,
    product: null,
    priceId: 0,
    loading: false,
    hasError: false,
    error: "",
  };
  
  export const registerSlice = createSlice({
    name: "registerUser",
    initialState,
    reducers: {
      loading: (state, action: PayloadAction<boolean>) => {
        state.loading = action.payload;
      },
      hasError: (state, action: PayloadAction<string>) => {
        state.hasError = true;
        state.error = `${action.payload}`;
      },
      setUser: (state, action: PayloadAction<UserDto>) => {
        state.user = action.payload;
      },
      setPrice: (state, action: PayloadAction<number>) => {
        state.priceId = action.payload
      }
    },
  });
  
  export const { hasError, loading, setUser, setPrice } = registerSlice.actions;

  

export const registerStateSelector = (state: RootState): RegisterState => state.register;

export default registerSlice.reducer;