import { Typography, Divider } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { SequenceDto } from "api";
import DrawerHeader from "components/drawers/DrawerHeader";
import SequenceDraggableList from "components/sequence/SequenceDraggableList";
import React from "react";
import { OnDragEndResponder } from "react-beautiful-dnd";

interface MySequenceListViewProps {
  sequence: SequenceDto;
  onDragEnd: OnDragEndResponder;
  handleClose?: () => void;
  onToogleFlip: (id: number) => void;
}

const MySequenceListView = ({
  sequence,
  onDragEnd,
  handleClose,
  onToogleFlip
}: MySequenceListViewProps) => {
  return (
    <>
      <DrawerHeader handleClose={() => handleClose!()} />
      <Typography variant="h1" sx={{ m: "0px 15px 5px 15px" }}>
        Min sekvens
      </Typography>
      <Divider sx={{ color: "black", mb: "2px" }} />
      <Stack spacing={1} sx={{ px: "15px", overflowY: "scroll" }}>
        <SequenceDraggableList
          items={sequence?.sequenceItems!}
          onDragEnd={onDragEnd}
          onToogleFlip={onToogleFlip}
        />
      </Stack>
    </>
  );
};

export default MySequenceListView;
