import { Alert, Divider, IconButton, InputBase, Paper } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import {
  onAddCommentAsync,
  sequenceStateSelector,
} from "store/slices/SequenceSlice";
import { useSelector } from "react-redux";
import { SequenceCommentDto } from "api";
import { authStateSelector } from "store/slices/authSlice";
import Iconify from "components/iconify/iconify";

interface SequenceCommentFormProps {
  sequenceId: number;
  parentId?: number;
  onSave?: () => void;
}

const SequenceCommentForm = ({
  sequenceId,
  parentId,
  onSave,
}: SequenceCommentFormProps) => {
  const dispatch = useDispatch();

  const { currentUser } = useSelector(authStateSelector);
  const { saving, hasError, error } = useSelector(sequenceStateSelector);

  const validationSchema = yup.object({
    comment: yup.string().required("Ange kommentar"),
  });

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={true}
      initialValues={{ comment: "" }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        const newComment = {
          sequenceId: sequenceId,
          parentId: parentId,
          message: values.comment,
          userId: currentUser?.id!,
        } as SequenceCommentDto;
        try {
          console.log("New comment:", newComment);
          const result = await dispatch<any>(onAddCommentAsync(newComment));
          if (result) {
            resetForm();
            if (onSave) onSave();
          }
        } catch (error) {
          console.log(error);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values, errors, handleChange, touched }) => (
        <Form noValidate autoComplete="off">
          {hasError && (
            <Alert severity="error" variant="outlined" sx={{ mb: "10px" }}>
              {error}
            </Alert>
          )}
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              id="comment"
              value={values.comment}
              onChange={handleChange}
              multiline
              placeholder="Skriv kommentar"
              inputProps={{ "aria-label": "Kommentar", maxLength: 150 }}
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label="spara"
              type="submit"
              disabled={isSubmitting}
            >
              <Iconify icon={"tabler:send"} />
            </IconButton>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default SequenceCommentForm;
