import * as React from "react";
import { Stack, TextField } from "@mui/material";

import { authStateSelector, loginAsync } from "store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import PasswordField from "./PasswordField";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Ange en gitltlig e-postadress")
    .required("E-postadress krävs"),
  password: yup.string().required("Lösenord krävs"),
});

export const LoginForm = (): JSX.Element => {
  const dispatch = useDispatch();
  const authState = useSelector(authStateSelector);

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={true}
      initialValues={{ email: "", password: "" }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        try {
          dispatch<any>(loginAsync(values.email, values.password));
        } catch (error) {
          console.log(error);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values, errors, handleChange, touched }) => (
        <Form noValidate autoComplete="off">
          <Stack
            direction={"column"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-postadress"
              name="email"
              autoComplete="email"
              disabled={authState.isAuthenticating}
              value={values.email}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              size="small"
            />

            <PasswordField
              required
              fullWidth
              name="password"
              label="Lösenord"
              id="password"
              disabled={authState.isAuthenticating}
              size="small"
            />

            <LoadingButton
              sx={{ my: 2 }}
              type="submit"
              endIcon={<ArrowForwardIcon />}
              fullWidth
              variant="contained"
              color="primary"
              loading={authState.isAuthenticating}
              disabled={isSubmitting}
            >
              Logga in
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
