import React, { useMemo } from "react";
import { LoginForm } from "components/forms/LoginForm";
import { authStateSelector } from "store/slices/authSlice";
import { useSelector } from "react-redux";
import { Link as RouterLink, Navigate, useLocation } from "react-router-dom";
import {
  Alert,
  Box,
  Link,
  Typography,
} from "@mui/material";
import AuthCard from "components/cards/AuthCard";

function useRedirectQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const AuthView = (): JSX.Element => {
  const authState = useSelector(authStateSelector);
  const redirectQuery = useRedirectQuery();
  
  if (authState.isAuthenticated && !authState.isAuthenticating)
    return <Navigate to={redirectQuery.get("redirect") ?? "/"} />;

  return (
    <AuthCard
      title="Välkommen till Soulwise Yoga!"
      form={
        <Box
          sx={{
            mb: 3,
          }}
        >
          {authState.hasError && (
            <Alert severity="error" variant="outlined">
              {authState.error}
            </Alert>
          )}
          <LoginForm />
        </Box>
      }
      footer={
        <Box>
          <Typography textAlign={"center"} gutterBottom>
            Ny här?{" "}
            <Link component={RouterLink} to="/register">
              Skapa konto
            </Link>
          </Typography>
          <Typography textAlign={"center"} gutterBottom>
            Glömt lösenord?{" "}
            <Link component={RouterLink} to="/forgotpassword">
              Klicka här
            </Link>
          </Typography>
        </Box>
      }
    />
  );
};
