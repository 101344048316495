import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CreateSequenceRequest,
  PositionDto,
  SequenceDto,
  SequenceImageDto,
  SequenceItemDto,
  SequenceItemType,
  SequenceService,
  Status,
} from "api";
import { AppThunk, RootState } from "store";
import { addSequence, removeSequence, updateSequence } from "./SequenceSlice";
import _ from "lodash";

export interface SequenceFormState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  sequence: SequenceDto | null;
  tempSequenceId: number;
}

const initialState: SequenceFormState = {
  loading: false,
  hasError: false,
  error: "",
  sequence: null,
  tempSequenceId: 10000000,
};

export const sequenceFormSlice = createSlice({
  name: "sequenceForm",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setNewSequence: (state) => {
      var defaultSequence = {
        sequenceId: 0,
        title: "",
        sequenceItems: [],
        status: Status._1
      } as SequenceDto;
      state.sequence = defaultSequence;
      state.hasError = false;
      state.error = "";
    },
    setSequence: (state, action: PayloadAction<SequenceDto>) => {
      state.sequence = action.payload;
      state.hasError = false;
      state.error = "";

      // Reorder list
      /*var sortedSequenceItems = _.sortBy(action.payload.sequenceItems!, "SortOrder");
      state.sequence.sequenceItems! = sortedSequenceItems.map((sequenceItem, index) => {
        const orderedItem = {...sequenceItem}
        orderedItem.sortOrder = index + 1 
        return orderedItem;
      });*/
    },
    copySequence: (state, action: PayloadAction<SequenceDto>) => {
      state.sequence = action.payload;
      state.sequence.sequenceId = 0;
      state.sequence.title = action.payload.title! + "-kopia";

      state.hasError = false;
      state.error = "";

      // Reorder list
      var sortedSequenceItems = _.sortBy(
        action.payload.sequenceItems!,
        "SortOrder"
      );
      state.sequence.sequenceItems! = sortedSequenceItems.map(
        (sequenceItem, index) => {
          var orderedItem = { ...sequenceItem };
          orderedItem.sortOrder = index + 1;
          return orderedItem;
        }
      );
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.sequence!.title = action.payload;
    },
    setYogaForm: (state, action: PayloadAction<number>) => {
      state.sequence!.yogaForm = action.payload;
    },
    setLevel: (state, action: PayloadAction<number>) => {
      state.sequence!.level = action.payload;
    },
    setTheme: (state, action: PayloadAction<string>) => {
      state.sequence!.theme = action.payload;
    },
    setScript: (state, action: PayloadAction<string>) => {
      state.sequence!.script = action.payload;
    },
    setMusicLink: (state, action: PayloadAction<string>) => {
      state.sequence!.musicLink = action.payload;
    },
    setOtherInformation: (state, action: PayloadAction<string>) => {
      state.sequence!.otherInformation = action.payload;
    },
    setPrivate: (state, action: PayloadAction<boolean>) => {
      state.sequence!.private! = action.payload;
    },
    setImage: (state, action: PayloadAction<SequenceImageDto>) => {
      state.sequence!.image = action.payload;
    },
    setStatus: (state, action: PayloadAction<Status>) => {
      state.sequence!.status = action.payload;
    },
    incrementTempSequenceItemId: (state) => {
      state.tempSequenceId = state.tempSequenceId + 1;
    },
    addSequenceItem: (state, action: PayloadAction<PositionDto>) => {
      var sortedSequenceItems = _.sortBy(
        state.sequence!.sequenceItems,
        "sortOrder"
      ).reverse();
      var newOrder =
        sortedSequenceItems.length > 0
          ? sortedSequenceItems[0].sortOrder! + 1
          : 1;

      state.tempSequenceId = state.tempSequenceId + 1;
      var newSequenceItem = {
        id: state.tempSequenceId,
        type: SequenceItemType._1,
        sortOrder: newOrder,
      } as SequenceItemDto;
      newSequenceItem.position = action.payload;

      state.sequence!.sequenceItems!.push(newSequenceItem);
    },
    addSequencePause: (state) => {
      var sortedSequenceItems = _.sortBy(
        state.sequence!.sequenceItems,
        "sortOrder"
      ).reverse();
      var newOrder =
        sortedSequenceItems.length > 0
          ? sortedSequenceItems[0].sortOrder! + 1
          : 1;

      state.tempSequenceId = state.tempSequenceId + 1;
      var newSequenceItem = {
        id: state.tempSequenceId,
        type: SequenceItemType._3,
        sortOrder: newOrder,
      } as SequenceItemDto;

      state.sequence!.sequenceItems!.push(newSequenceItem);
    },
    addSequenceNote: (state, action: PayloadAction<string>) => {
      var sortedSequenceItems = _.sortBy(
        state.sequence!.sequenceItems,
        "sortOrder"
      ).reverse();
      var newOrder =
        sortedSequenceItems.length > 0
          ? sortedSequenceItems[0].sortOrder! + 1
          : 1;

      state.tempSequenceId = state.tempSequenceId + 1;
      var newSequenceItem = {
        id: state.tempSequenceId,
        type: SequenceItemType._2,
        information: action.payload,
        sortOrder: newOrder,
      } as SequenceItemDto;

      state.sequence!.sequenceItems!.push(newSequenceItem);
    },
    removeSequenceItem: (state, action: PayloadAction<SequenceItemDto>) => {
      const index = state.sequence!.sequenceItems!.findIndex(
        (c) => c.id === action.payload.id
      );

      if (index! > -1) {
        state.sequence!.sequenceItems!.splice(index!, 1);
      }
    },
    setSequenceItemList: (state, action: PayloadAction<SequenceItemDto[]>) => {
      state.sequence!.sequenceItems! = action.payload.map(
        (sequenceItem, index) => {
          var orderedItem = { ...sequenceItem };
          orderedItem.sortOrder = index + 1;
          return orderedItem;
        }
      );
    },
    flipSequenceItem: (state, action: PayloadAction<number>) => {
      state.sequence!.sequenceItems! = state.sequence!.sequenceItems!.map(
        (sequenceItem, index) => {
          if(sequenceItem.id === action.payload)
            {
              const newItem = {...sequenceItem}
              const imageFlip = newItem.imageFlip ? newItem.imageFlip : 1
              newItem.imageFlip = (imageFlip === 1 ? -1: 1)
              return newItem;
            }
            else
            return sequenceItem;
        }
      );

    },
    resetState: (state) => {
      state.loading = false;
      state.hasError = false;
      state.error = "";
      state.sequence = null;
    },
    updateSequenceItem: (state, action: PayloadAction<SequenceItemDto>) => {
      state.sequence!.sequenceItems! = state.sequence!.sequenceItems!.map(
        (sequence) =>
          sequence.id === action.payload.id ? action.payload : sequence
      );
    },
  },
});

export const {
  hasError,
  loading,
  setSequence,
  copySequence,
  setTitle,
  setYogaForm,
  setLevel,
  setTheme,
  setScript,
  setMusicLink,
  setOtherInformation,
  setPrivate,
  setImage,
  setStatus,
  addSequenceItem,
  addSequencePause,
  addSequenceNote,
  setSequenceItemList,
  removeSequenceItem,
  resetState,
  setNewSequence,
  updateSequenceItem,
  incrementTempSequenceItemId,
  flipSequenceItem,
} = sequenceFormSlice.actions;

export const createNewSequence = (): AppThunk => async (dispatch, getState) => {
  try {
    var updateResult = false;
    dispatch(loading(true));

    var request = {
      title: getState().sequenceForm.sequence!.title,
      yogaForm: getState().sequenceForm.sequence!.yogaForm,
      level: getState().sequenceForm.sequence!.level,
      theme: getState().sequenceForm.sequence!.theme,
      script: getState().sequenceForm.sequence!.script,
      musicLink: getState().sequenceForm.sequence!.musicLink,
      otherInformation: getState().sequenceForm.sequence!.otherInformation,
      sequenceItems: [...getState().sequenceForm.sequence!.sequenceItems!],
      private: getState().sequenceForm.sequence!.private,
      status: getState().sequenceForm.sequence!.status,
      sequenceImage: getState().sequenceForm.sequence!.image?.sequenceImageId,
    } as CreateSequenceRequest;

    await SequenceService.postApiSequencesCreateUserSequence(request)
      .then(async (result) => {
        dispatch(addSequence(result));
        updateResult = true;
      })
      .catch((e: Error) => {
        dispatch(hasError((e as Error).message));
      });

    return updateResult;
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};

export const updateExistingSequence =
  (): AppThunk => async (dispatch, getState) => {
    try {
      var updateResult = false;
      dispatch(loading(true));

      var request = {
        sequenceId: getState().sequenceForm.sequence!.sequenceId,
        title: getState().sequenceForm.sequence!.title,
        yogaForm: getState().sequenceForm.sequence!.yogaForm,
        level: getState().sequenceForm.sequence!.level,
        theme: getState().sequenceForm.sequence!.theme,
        script: getState().sequenceForm.sequence!.script,
        musicLink: getState().sequenceForm.sequence!.musicLink,
        otherInformation: getState().sequenceForm.sequence!.otherInformation,
        sequenceItems: [...getState().sequenceForm.sequence!.sequenceItems!],
        private: getState().sequenceForm.sequence!.private,
        status: getState().sequenceForm.sequence!.status,
        image: getState().sequenceForm.sequence!.image,
      } as SequenceDto;

      await SequenceService.putApiSequencesUpdateUserSequence(request)
        .then(async (result) => {
          dispatch(updateSequence(result));
          updateResult = true;
        })
        .catch((e: Error) => {
          dispatch(hasError((e as Error).message));
        });

      return updateResult;
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const deleteSequence =
  (sequenceId: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      const SequenceById = await SequenceService.deleteApiSequences(sequenceId);
      dispatch(removeSequence(SequenceById));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const sequenceFormStateSelector = (
  state: RootState
): SequenceFormState => state.sequenceForm;

export default sequenceFormSlice.reducer;
