import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, Drawer, Grid } from "@mui/material";
import { PhilosophyDto } from "api";
import DrawerContent from "components/drawers/DrawerContent";
import DrawerHeader from "components/drawers/DrawerHeader";
import PhilosophyInfoView from "views/CardViews/PhilosophyInfoView";
import { useState } from "react";

export interface PhilosophyCardProps {
  data: PhilosophyDto | null;
}

export default function HomePhilosophyCard({ data }: PhilosophyCardProps) {
  const [open, setOpen] = useState(false);

  function handleClose(): void {
    setOpen(false);
  }
  return (
    <>
      <Card variant="fullInfo" sx={{height:"170px", backgroundColor:"#fff",}}>
        <CardActionArea onClick={() => setOpen(true)} sx={{ height:"100%"}}>
          <CardContent sx={{ p: "1.5rem" }}>
            <Grid mb="10px">
              <Typography variant="h2" fontWeight={400}>
                {data?.cardTitel}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="h4">{data?.cardDescription}</Typography>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
      <Drawer
        open={open}
        anchor={"right"}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#FFF",
            maxWidth: "1000px",
          },
        }}
      >
        <DrawerHeader handleClose={handleClose} />
        <DrawerContent>
          <PhilosophyInfoView philosophy={data!}  />
        </DrawerContent>
      </Drawer>
    </>
  );
}
