import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Drawer,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Box, Stack, maxWidth } from "@mui/system";
import { BackgroundPaper } from "components/papers/BackgroundPaper";
import { SequenceDto } from "api/models/SequenceDto";

import { IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  copySequence,
  sequenceFormStateSelector,
  setPrivate,
  setSequence,
  updateExistingSequence,
} from "store/slices/SequenceFormSlice";
import { PositionDto, SequenceItemDto } from "api";
import { getLevelTitle, getYogaFormText } from "utils/model";
import { useSelector } from "react-redux";
import { authStateSelector } from "store/slices/authSlice";
import SequenceDeleteDialog from "components/sequence/SequenceDeleteDialog";
import PositionsInfoView from "views/CardViews/PositionsInfoView";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonIcon from "@mui/icons-material/Person";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import DrawerCloseButton from "components/drawers/DrawerCloseButton";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import SequenceListItem from "components/sequence/SequenceListItem";
import { useReactToPrint } from "react-to-print";
import { SequencePrintView } from "views/OtherViews/SequencePrintView";
import SequenceCommentButton from "components/sequence/SequenceCommentButton";
import DrawerHeader from "components/drawers/DrawerHeader";
import DrawerContent from "components/drawers/DrawerContent";
import ShareIcon from "@mui/icons-material/Share";
import SequenceShareDialog from "components/sequence/SequenceShareDialog";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import VideoDialog from "components/video/VideoDialog";

export interface SequencesInfoViewProps {
  sequence?: SequenceDto | null;
  handleClose?: () => void;
}

export default function SequenceInfoView({
  sequence,
  handleClose,
}: SequencesInfoViewProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { currentUser } = useSelector(authStateSelector);
  const sequenceForm = useSelector(sequenceFormStateSelector);

  useEffect(() => {
    dispatch(setSequence(sequence!));
  }, [sequence]);

  function handleCopySequence(sequence: SequenceDto): void {
    dispatch(copySequence(sequence!));
    navigate("/newSequence");
  }

  function handleEditSequence(sequence: SequenceDto): void {
    dispatch(setSequence(sequence!));
    navigate("/newSequence");
  }

  function handlePrivateChange(e: React.ChangeEvent<HTMLInputElement>): void {
    dispatch(setPrivate(e.target.checked));
    dispatch<any>(updateExistingSequence());
  }

  function handleShareSequence(sequenceId: number): void {
    console.log("Shared");
  }

  return (
    <>
      <div style={{ display: "none" }}>
        <SequencePrintView sequence={sequence} ref={componentRef} />
      </div>
      <DrawerHeader
        handleClose={() => handleClose!()}
        sx={{ backgroundColor: "#FFFFFF" }}
        toolbar={
          <Stack direction={"row"}>
            <SequenceCommentButton item={sequence!} />
            <Tooltip title="Kopiera">
              <span>
                <IconButton
                  aria-label="copy"
                  disabled={sequence?.user?.id !== currentUser?.id}
                  onClick={() => handleCopySequence(sequence!)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Redigera">
              <span>
                <IconButton
                  aria-label="edit"
                  disabled={sequence?.user?.id !== currentUser?.id}
                  onClick={() => handleEditSequence(sequence!)}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>

            <SequenceShareDialog
              sequence={sequence!}
              disabled={sequence?.user?.id !== currentUser?.id}
              onShared={handleShareSequence}
            />
            <SequenceDeleteDialog
              sequence={sequence!}
              disabled={sequence?.user?.id !== currentUser?.id}
              handleDeleted={handleClose!}
            />

            <Tooltip title="Skriv ut">
              <span>
                <IconButton aria-label="print" onClick={handlePrint}>
                  <PrintIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        }
      />
      <DrawerContent>
        <Stack
          sx={{
            width: { xs: "100%", sm: "600px", md: "900px" },
          }}
        >
          <Stack direction={"row"} spacing={1} sx={{ alignItems: "center" }}>
            <Typography
              fontSize={"20px"}
              fontWeight={600}
              noWrap
              sx={{ maxWidth: { xs: "390px", sm: "100%" } }}
            >
              {sequence!.title ? sequence!.title : "[Ny sekvens]"}
            </Typography>
            {(sequenceForm.sequence!
              ? sequenceForm.sequence!.private
              : false) && <LockOutlinedIcon fontSize="small" />}
          </Stack>

          <Grid container spacing={1} sx={{ pt: "10px" }}>
            <Grid item xs={12} sm={6} sx={{}}>
              <Stack spacing={2} sx={{}}>
                <Box
                  sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    p: 2,
                  }}
                >
                  <Table
                    size="small"
                    sx={{
                      "& .MuiTableCell-root": {
                        borderBottom: "none",
                        padding: 0,
                      },
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ display: "flex" }}>
                          <SignalCellularAltIcon
                            sx={{ pr: "10px" }}
                            fontSize="small"
                          />
                          <Typography fontSize={14} fontWeight={600}>
                            Nivå
                          </Typography>
                        </TableCell>
                        <TableCell>{getLevelTitle(sequence?.level!)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ display: "flex" }}>
                          <SportsGymnasticsIcon
                            sx={{ pr: "10px" }}
                            fontSize="small"
                          />
                          <Typography fontSize={14} fontWeight={600}>
                            Yogaform
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {getYogaFormText(sequence?.yogaForm!)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ display: "flex" }}>
                          <FormatListBulletedIcon
                            sx={{ pr: "10px" }}
                            fontSize="small"
                          />
                          <Typography fontSize={14} fontWeight={600}>
                            Positioner
                          </Typography>
                        </TableCell>
                        <TableCell>{sequence!.sequenceItems?.length}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ display: "flex" }}>
                          <PersonIcon sx={{ pr: "10px" }} fontSize="small" />
                          <Typography fontSize={14} fontWeight={600}>
                            Skapad av
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Link
                            to={"/user/profile/" + sequence!.user?.id}
                            style={{ textDecoration: "none" }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              {sequence!.user?.firstName}{" "}
                              {sequence!.user?.lastName}
                            </Typography>
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ display: "flex" }}>
                          <LockOutlinedIcon
                            sx={{ pr: "10px" }}
                            fontSize="small"
                          />
                          <Typography fontSize={14} fontWeight={600}>
                            Privat
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            disabled={sequence?.user?.id !== currentUser?.id}
                            control={
                              <Switch
                                size="small"
                                onChange={(e) => handlePrivateChange(e)}
                                checked={
                                  sequenceForm.sequence!
                                    ? sequenceForm.sequence!.private
                                    : false
                                }
                              />
                            }
                            label=""
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    p: 2,
                  }}
                >
                  <Stack>
                    <Typography sx={{ fontWeight: "bold" }}>Tema</Typography>
                    <Typography
                      style={{ overflowWrap: "break-word" }}
                      sx={{ pb: "15px" }}
                    >
                      {sequence!.theme ? sequence!.theme : "[Inget angivet]"}
                    </Typography>
                    {sequence?.videos && sequence?.videos.length > 0 && (
                      <>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Video
                        </Typography>
                        <List dense>
                          {sequence?.videos &&
                            sequence?.videos.map((v, index) => (
                              <VideoDialog video={v} index={index} />
                            ))}
                        </List>
                      </>
                    )}

                    <Typography sx={{ fontWeight: "bold" }}>Manus</Typography>
                    <Typography
                      style={{
                        overflowWrap: "break-word",
                        whiteSpace: "pre-line",
                      }}
                      sx={{ pb: "15px" }}
                    >
                      {sequence!.script ? sequence!.script : "[Inget angivet]"}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Länk till spellista/musik
                    </Typography>
                    <Typography
                      style={{ overflowWrap: "break-word" }}
                      sx={{ pb: "15px", maxWidth: { xs: "330px" } }}
                    >
                      {sequence!.musicLink
                        ? sequence!.musicLink
                        : "[Inget angivet]"}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Övrig information
                    </Typography>
                    <Typography
                      style={{
                        overflowWrap: "break-word",
                        whiteSpace: "pre-line",
                      }}
                      sx={{ pb: "15px" }}
                    >
                      {sequence!.otherInformation
                        ? sequence!.otherInformation
                        : "[Inget angivet]"}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6} sx={{}}>
              <Typography
                variant="h6"
                sx={{ display: { xs: "flex", sm: "none" } }}
              >
                Program
              </Typography>
              <List
                sx={{
                  p: 0,
                  "& .MuiListItem-root": {
                    mx: 0,
                    "&:first-of-type": { mt: 0 },
                  },
                }}
              >
                {sequence!.sequenceItems &&
                  sequence!.sequenceItems.map((item, index) => (
                    <SequenceListItem key={index} item={item} />
                  ))}
              </List>
            </Grid>
          </Grid>
        </Stack>
      </DrawerContent>
    </>
  );
}
