import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Drawer,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { SequenceDto } from "api";
import { alpha } from "@mui/material/styles";
import SequenceCard from "components/cards/SequenceCard";
import Iconify from "components/iconify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSequenceByUser } from "store/selectors/sequence";
import { loadUserAsync, userStateSelector } from "store/slices/userSlice";
import SequenceInfoView from "views/CardViews/SequenceInfoView";
import { StdView } from "views/OtherViews/StdView";

const ProfileView = () => {
  let params = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState<SequenceDto>();

  const { user } = useSelector(userStateSelector);
  const sequenceList = useSelector((state) => getSequenceByUser(state, user));

  function handleClose(): void {
    setOpen(false);
  }

  function handleClick(sequence: SequenceDto): void {
    setSelectedSequence(sequence);
    setOpen(true);
  }

  function validateUrl(uri: string): string {
    if (uri === "" || uri === undefined || uri === null) return uri;

    if (!uri.match(/^[a-zA-Z]+:\/\//)) {
      uri = "http://" + uri;
    }

    return uri;
  }

  useEffect(() => {
    dispatch<any>(loadUserAsync(parseInt(params.id!)));
  }, [dispatch, params]);

  const renderAvatar = (
    <Card
      sx={{
        p: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Avatar
        alt={user?.firstName!}
        src={user?.imageUri!}
        color={"secondary"}
        sx={{ width: 56, height: 56 }}
      />
    </Card>
  );

  const renderAbout = (
    <Card sx={{ pb: "20px" }}>
      <CardHeader title="Om" />
      <Stack spacing={2} sx={{ px: 3 }}>
        <Box
          sx={{
            m: "auto",
            width: 144,
            height: 144,
            cursor: "pointer",
            overflow: "hidden",
            borderRadius: "50%",
            border: (theme) =>
              `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
            p: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            alt={user?.firstName!}
            src={user?.imageUri!}
            color={"secondary"}
            sx={{
              width: 1,
              height: 1,
              borderRadius: "50%",
            }}
          />
        </Box>

        <Typography
          variant="body2"
          sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
        >
          {user ? user.about : ""}
        </Typography>

        {user?.city! && (
          <Stack direction="row" spacing={2}>
            <Iconify icon="mingcute:location-fill" width={24} />

            <Box sx={{ typography: "body2" }}>{user?.city}</Box>
          </Stack>
        )}
        {user?.location! && (
          <Stack direction="row" spacing={2}>
            <Iconify icon="mdi:shop-location" width={24} />

            <Box sx={{ typography: "body2" }}>{user?.location}</Box>
          </Stack>
        )}
        {/* 
        <Stack direction="row" sx={{ typography: "body2" }}>
          <Iconify icon="fluent:mail-24-filled" width={24} sx={{ mr: 2 }} />
          {user?.email}
        </Stack>
        */}
      </Stack>
    </Card>
  );

  const renderSocials = (
    <Card sx={{ pb: "20px" }}>
      <CardHeader title="Social" />

      <Stack spacing={2} sx={{ px: 3 }}>
        {user?.instagramUri! && (
          <Stack
            spacing={2}
            direction="row"
            sx={{ wordBreak: "break-all", typography: "body2" }}
          >
            <Iconify
              icon="ant-design:instagram-filled"
              width={24}
              sx={{
                flexShrink: 0,
                color: "#000",
              }}
            />
            <Link
              color="inherit"
              href={validateUrl(user?.instagramUri!)}
              target="_blank"
            >
              {user?.instagramUri}
            </Link>
          </Stack>
        )}
        {user?.facebookUri! && (
          <Stack
            spacing={2}
            direction="row"
            sx={{ wordBreak: "break-all", typography: "body2" }}
          >
            <Iconify
              icon="eva:facebook-fill"
              width={24}
              sx={{
                flexShrink: 0,
                color: "#000",
              }}
            />
            <Link
              color="inherit"
              href={validateUrl(user?.facebookUri!)}
              target="_blank"
            >
              {user?.facebookUri}
            </Link>
          </Stack>
        )}
        {user?.webUri! && (
          <Stack
            spacing={2}
            direction="row"
            sx={{ wordBreak: "break-all", typography: "body2" }}
          >
            <Iconify
              icon="mdi:web"
              width={24}
              sx={{
                flexShrink: 0,
                color: "#000000",
              }}
            />
            <Link
              color="inherit"
              href={validateUrl(user?.webUri!)}
              target="_blank"
            >
              {user?.webUri}
            </Link>
          </Stack>
        )}
      </Stack>
    </Card>
  );

  const renderSequence = (
    <Card sx={{ pb: "20px" }}>
      <CardHeader title="Delade sekvenser" />
      <Stack spacing={1} sx={{ px: 3 }}>
        <Grid container spacing={{ xs: 1, sm: 1 }}>
          {sequenceList &&
            sequenceList.map((sequence, index) => (
              <Grid xs={12} sm={6} md={6} lg={4} key={index}>
                <SequenceCard
                  data={sequence}
                  handleClick={() => handleClick(sequence)}
                />
              </Grid>
            ))}
        </Grid>
        {sequenceList && sequenceList.length === 0 && (
          <Typography variant="body2">Saknar delade sekvenser</Typography>
        )}
      </Stack>
    </Card>
  );

  return (
    <StdView title={user?.firstName! + " " + user?.lastName!}>
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <Stack spacing={3}>
            {renderAbout}
            {renderSocials}
          </Stack>
        </Grid>

        <Grid xs={12} md={8}>
          <Stack spacing={3}>{renderSequence}</Stack>
        </Grid>
      </Grid>
      <Drawer
        open={open}
        anchor={"right"}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#F5F4F0",
          },
        }}
      >
        <SequenceInfoView
          sequence={selectedSequence}
          handleClose={handleClose}
        />
      </Drawer>
    </StdView>
  );
};

export default ProfileView;
