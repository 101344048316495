import { Alert, Grid, Toolbar, Typography } from "@mui/material";
import { StdView } from "views/OtherViews/StdView";

export default function MySubscriptionsView() {
  return (
    <StdView title={"Min prenumeration"}>
      <Alert severity="info" variant="soulwise">
        Du har en provprenumeration av Soulwise yoga som löper tills vidare
      </Alert>
    </StdView>
  );
}
