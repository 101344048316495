import {
  Avatar,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import PositionForm from "components/forms/PositionForm";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPositionsByFilter } from "store/selectors/position";
import { loadPositionsAsync } from "store/slices/positionSlice";
import { StdView } from "views/OtherViews/StdView";
import { setPosition } from "store/slices/positionFormSlice";
import { authStateSelector } from "store/slices/authSlice";
import { Link } from "react-router-dom";
import { AdminService } from "api";

const AdminView = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(authStateSelector);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {}, [dispatch]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const setNewVersionAvailable = async () => {
    try {
      const result = await AdminService.postApiAdminSetnewversion();
      setOpen(true)
    } catch (error) {
      
    }
    

  };

  return (
    <StdView title={"Admin"}>
      {currentUser?.isAdmin! ? (
        <Stack>
          <Button
            onClick={setNewVersionAvailable}
            variant="contained"
            sx={{ width: 300, mb: 2 }}
          >
            Notifiera om ny version
          </Button>
          <Link to="positions">Redigera positioner</Link>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            message="Ny version aktiverad"
          />
        </Stack>
      ) : (
        <Typography>Not Authorized</Typography>
      )}
    </StdView>
  );
};

export default AdminView;
