import { Form, Formik } from "formik";
import * as yup from "yup";
import { Button, Grid, Box, Alert, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  authStateSelector,
  updatePasswordAsync,
} from "store/slices/authSlice";
import PasswordField from "./PasswordField";
import { SoulwisePaper } from "components/papers/SoulwisePaper";

const validationSchema = yup.object({
  currentPassword: yup.string().required("Nuvarande lösenord krävs"),
  newPassword: yup
    .string()
    .required("Nytt lösenord krävs")
    .notOneOf([yup.ref("currentPassword"), null], "Ange ett nytt lösenord")
    .matches(
      /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Lösenordet måste innehålla minst 8 karaktärer, en versal, ett nummer och ett specialtecken"
    ),
  newPasswordConfirm: yup
    .string()
    .test(
      "newPassword",
      "Lösenordet matchar inte",
      function (emailConfirmation) {
        return emailConfirmation === this.parent.newPassword;
      }
    ),
});

export default function ChangePasswordForm() {
  const dispatch = useDispatch();
  const authState = useSelector(authStateSelector);

  //Updates currentUser state
 /* useEffect(() => {
    dispatch<any>(getMeAsync());
  }, [dispatch]);*/

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={true}
      initialValues={{
        currentPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        try {
          await dispatch<any>(
            updatePasswordAsync(values.currentPassword, values.newPassword)
          );
          resetForm();

        } catch (error) {
          console.log(error);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values, errors, handleChange, touched }) => (
        <Form noValidate autoComplete="off">
          <SoulwisePaper>
            <Box>
              {authState.hasError && (
                <Alert severity="error">{authState.error}</Alert>
              )}
            </Box>

            <Grid container sx={{}}>
              <Grid item xs={12}>
                <Typography>Nuvarande lösenord *</Typography>
                <PasswordField
                  size="small"
                  required
                  fullWidth
                  name="currentPassword"
                  id="currentPassword"
                  disabled={authState.isAuthenticating}
                  sx={{maxWidth:"400px"}}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Nytt lösenord *</Typography>
                <PasswordField
                  size="small"
                  required
                  fullWidth
                  name="newPassword"
                  id="newPassword"
                  disabled={authState.isAuthenticating}
                  sx={{maxWidth:"400px"}}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Bekräfta nytt lösenord *</Typography>
                <PasswordField
                  size="small"
                  required
                  fullWidth
                  name="newPasswordConfirm"
                  id="newPasswordConfirm"
                  disabled={authState.isAuthenticating}
                  sx={{maxWidth:"400px"}}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting}
                >
                  Ändra
                </Button>
              </Grid>
            </Grid>
          </SoulwisePaper>
        </Form>
      )}
    </Formik>
  );
}
