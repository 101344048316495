import { Alert, Link as MuiLink} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as ReactRouterLink } from "react-router-dom";
import { authStateSelector } from "store/slices/authSlice";
import { hideNewVersionBannerAsync } from "store/slices/userSlice";

const NewVersionAlert = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(authStateSelector);

  const onHideNewVersionAlert = async () => {
    await dispatch<any>(hideNewVersionBannerAsync(currentUser?.id!));
  };
  return (
    <>
      {currentUser?.showNewVersionBanner && (
        <Alert severity="info"  onClose={onHideNewVersionAlert} sx={{mb:'15px'}}>
          Soulwise har uppdaterat till en ny version, vad som är nytt kan du
          läsa <MuiLink component={ReactRouterLink} to={"/versions"} underline="always">här</MuiLink>.
        </Alert>
      )}
    </>
  );
};
export default NewVersionAlert;
