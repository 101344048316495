import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Drawer,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import PositionsSlider from "../position/PositionsSlider";
import React, { useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useDispatch } from "react-redux";
import {
  sequenceFilterStateSelector,
  setSequenceLevels,
  setYogaForm,
  resetFilter,
} from "store/slices/sequenceFilterSlice";
import {
  getLevelTitle,
  getYogaFormText,
  levelIds,
  yogaFormIds,
} from "utils/model";
import { useSelector } from "react-redux";
import DrawerCloseButton from "./DrawerCloseButton";
import DrawerHeader from "./DrawerHeader";

export default function SequenceFilterDrawer() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { noOfPositions, sequenceLevels, yogaForm } = useSelector(
    sequenceFilterStateSelector
  );

  const handleLevels = (
    event: React.MouseEvent<HTMLElement>,
    newLevels: number[]
  ) => {
    dispatch(setSequenceLevels(newLevels));
  };

  const handleYogaForms = (
    event: React.MouseEvent<HTMLElement>,
    newYogaForms: number[]
  ) => {
    dispatch(setYogaForm(newYogaForms));
  };

  const List = () => (
    <Stack spacing={1} sx={{ maxWidth: 400 }}> 
      <DrawerHeader
        handleClose={() => setOpen(false)}
        sx={{ backgroundColor: "#FFFFFF" }}
      />   
      <Box
        sx={{
          px: 3,
          "& .MuiToggleButtonGroup-grouped": {
            borderRadius: "4px !important",
            mr: 1,
            border: "1px solid lightgrey !important",
            mt: "10px",
          },
        }}
      >
        <Typography
          fontSize={"2rem"}
          fontWeight="light"
          sx={{ pb: "5px", opacity: "1" }}
        >
          Filtrera sekvenser
        </Typography>
        <Stack spacing={3}>
          <Box>
            <Typography>NIVÅ</Typography>
            <ToggleButtonGroup
              size="small"
              value={sequenceLevels}
              onChange={handleLevels}
              aria-label="device"
              color="primary"
              //sx={{ flexWrap: "wrap" }}
            >
              {levelIds.map((level, index) => (
                <ToggleButton value={level} key={index} aria-label="laptop">
                  {getLevelTitle(level)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
          <Box>
            <Typography>
              ANTAL POSITIONER
            </Typography>
            <PositionsSlider value={noOfPositions} />
          </Box>

          <Box>
            <Typography>YOGAFORM</Typography>
            <ToggleButtonGroup
              value={yogaForm}
              onChange={handleYogaForms}
              aria-label="device"
              color="primary"
              size="small"
              sx={{ flexWrap: "wrap", justifyItems: "start" }}
            >
              {yogaFormIds.map((yogaForm, index) => (
                <ToggleButton value={yogaForm} key={index} aria-label="laptop">
                  {getYogaFormText(yogaForm)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          maxWidth: 400,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F5F4F0",
          p: 1,
        }}
      >
        <Button
          color="primary"
          variant="contained"
          sx={{
            width: "200px",
            height: "32x",
            borderRadius: "50px",
          }}
          onClick={() => dispatch(resetFilter())}
        >
          Rensa alla
        </Button>
      </Box>
    </Stack>
  );

  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        startIcon={<FilterListIcon />}
        sx={{
          backgroundColor: "#D0D6D2",
          height: "46px",
          color: "#171A17",
          borderRadius: "26px",
        }}
      >
        Filter
      </Button>
      <Drawer open={open} anchor={"right"} onClose={() => setOpen(false)}>
        {List()}
      </Drawer>
    </div>
  );
}
