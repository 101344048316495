import {
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  Button,
  IconButton,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { sequenceFormStateSelector } from "store/slices/SequenceFormSlice";
import { useSelector } from "react-redux";

export interface SequenceCancelDialogProps {}

const SequenceCancelDialog = ({}: SequenceCancelDialogProps) => {
  const navigate = useNavigate();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const { sequence } = useSelector(sequenceFormStateSelector);

  const handleCancelClick = () => {
    setIsCancelDialogOpen(true);
  };

  const handleCancelDialogClose = () => {
    setIsCancelDialogOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleCancelClick}
        sx={{ display: { xs: "flex", md: "none" } }}
      >
        <CancelIcon />
      </IconButton>

      <Button
        variant="soft"
        color="primary"
        sx={{ borderRadius: "46px", display: { xs: "none", md: "flex" }, mx:"4px" }}
        onClick={handleCancelClick}
      >
        Avbryt
      </Button>

      <Dialog
        open={isCancelDialogOpen}
        onClose={handleCancelDialogClose}
        maxWidth={"xs"}
      >
        <DialogTitle>Avbryta?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Är du verkligen säker på att du vill avbryta (informationen sparas
            ej)?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Grid container>
            <Grid item xs={12}>
              <Button
                sx={{
                  width: "150px",
                  borderRadius: "46px",
                  mb: "10px",
                  justifyContent: "center",
                  
                }}
                
                variant="contained" color="primary"
                onClick={() => navigate(-1)}
              >
                Ja
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                sx={{
                  width: "150px",
                  borderRadius: "46px",
                  justifyContent: "center",
                }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  handleCancelDialogClose();
                }}
              >
                Nej
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SequenceCancelDialog;
