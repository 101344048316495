import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden,
  IconButton,
  Tooltip,
} from "@mui/material";
import { SequenceDto } from "api";
import React from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteSequence } from "store/slices/SequenceFormSlice";

export interface SequenceDeleteDialogProps {
  sequence: SequenceDto;
  handleDeleted: () => void;
  disabled: boolean;
}

const SequenceDeleteDialog = ({
  sequence,
  handleDeleted,
  disabled,
}: SequenceDeleteDialogProps) => {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (sequenceId?: number) => {
    console.log(sequenceId);
    dispatch<any>(deleteSequence(sequenceId!));
    handleDeleted();
    handleClose();
  };

  return (
    <>
      <Tooltip title="Ta bort">
        <span>
          <IconButton
            aria-label="delete"
            disabled={disabled}
            onClick={handleClickOpen}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vill du verkligen ta bort sekvensen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Avbryt
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDelete(sequence?.sequenceId!)}
            autoFocus
          >
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SequenceDeleteDialog;
