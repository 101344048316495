import { Box } from "@mui/system";
import { SequenceCommentDto } from "api";
import React from "react";
import _ from "lodash";
import SequenceCommentItem from "./SequenceCommentItem";
import { getDateFromString } from "utils/dateUtils";
import Grid from "@mui/system/Unstable_Grid";

type SequenceCommentListProps = {
  sequenceId: number;
  comments: SequenceCommentDto[];
};

const SequenceCommentList = ({
  sequenceId,
  comments,
}: SequenceCommentListProps) => {
  console.log("comments", comments)

  const sortedComments = _.sortBy(comments, "postedAt"); 
  
  return (
    <Grid container>
      {sortedComments.map((comment) => {
        const { id, replyComments, user, message, postedAt, parentId, userId } =
          comment;

        if(parentId !== null) return;

        const hasReply = (replyComments != null);

        const sortedReplyComments = _.sortBy(replyComments, "postedAt"); 

        return (
          <Grid xs={12} key={id} >
            <SequenceCommentItem
              id={id!}
              userId={userId!}
              sequenceId={sequenceId}
              name={user?.firstName!}
              message={message!}
              postedAt={getDateFromString(postedAt!)!}
              avatarUrl={user?.imageUri!}

            />
            {hasReply &&
              sortedReplyComments!.map((reply) => {
                return (
                  <SequenceCommentItem
                    id={reply.id!}
                    userId={reply.userId!}
                    sequenceId={sequenceId}
                    key={reply.id}
                    name={reply.user?.firstName || ""}
                    message={reply.message!}
                    postedAt={getDateFromString(reply.postedAt!)!}
                    avatarUrl={reply.user?.imageUri || ""}
                    hasReply
                  />
                );
              })}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SequenceCommentList;
