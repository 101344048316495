import {
  Badge,
  BadgeProps,
  Drawer,
  IconButton,
  SxProps,
  styled,
} from "@mui/material";
import { SequenceDto } from "api";
import Iconify from "components/iconify";
import React, { useState } from "react";
import SequenceCommentView from "views/CardViews/SequenceCommentView";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 1,
    top: 4,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 3px",
    fontSize: 9,
    height: 16,
    minWidth: 16,
  },
}));

export interface SequenceLikeButtonProps {
  item: SequenceDto;
  sx?: SxProps;
}

const SequenceCommentButton = ({ item, sx }: SequenceLikeButtonProps) => {
  const [open, setOpen] = useState(false);
  const handleLikeSequence = () => {};

  function handleClose(): void {
    setOpen(false);
  }

  const hasUsercomments = (): boolean => {
    const hasComments: number = item.comments ? item.comments?.length! : 0;
    if (hasComments > 0) {
      return true;
    } else return false;
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)} sx={{ ...sx }}>
        <StyledBadge badgeContent={item.comments?.length!} color="secondary">
          {hasUsercomments() ? (
            <Iconify
              icon="tabler:message-circle-2-filled"
              color={(theme) => theme.palette.primary.main}
              width={24}
            />
          ) : (
            <Iconify icon="tabler:message-circle" width={24} />
          )}
        </StyledBadge>
      </IconButton>
      <Drawer
        open={open}
        anchor={"right"}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            maxWidth: { xs: "100%", md: "500px" },
            width:{xs: "100%"},
            backgroundColor: "#F5F4F0",
          },
        }}
      >
        <SequenceCommentView sequence={item} handleClose={handleClose} />
      </Drawer>
    </>
  );
};

export default SequenceCommentButton;
