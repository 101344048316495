import Typography from "@mui/material/Typography";
import {
  Box,
  Drawer,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";
import SequenceFilterDrawer from "components/drawers/SequenceFilterDrawer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getSequenceDraft,
  getSequenceFinished,

} from "store/selectors/sequence";
import { loadMySequencesAsync, loadSharedSequencesAsync, sequenceStateSelector } from "store/slices/SequenceSlice";
import { setSearchString } from "store/slices/sequenceFilterSlice";
import SearchIcon from "@mui/icons-material/Search";
import { getMeAsync } from "store/slices/authSlice";
import SequenceCard from "components/cards/SequenceCard";
import { SequenceDto } from "api";
import SequenceInfoView from "views/CardViews/SequenceInfoView";
import { StdView } from "views/OtherViews/StdView";

export default function MySequenceView() {
  const dispatch = useDispatch<any>();
  const [open, setOpen] = useState(false);
  const sequenceFinished = useSelector(getSequenceFinished);
  const sequenceDraft = useSelector(getSequenceDraft);
  const {sharedSequences} = useSelector(sequenceStateSelector);
  const [selectedSequence, setSelectedSequence] = useState<SequenceDto>();

  useEffect(() => {
    dispatch(getMeAsync());
    dispatch(loadMySequencesAsync());
    dispatch(loadSharedSequencesAsync());
  }, [dispatch]);

  
  useEffect(() => {
    const sequence = sequenceFinished.find((s) => s.sequenceId == selectedSequence?.sequenceId)
    if(sequence)
      setSelectedSequence(sequence)
  }, [sequenceFinished]);

  const onSearchEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchString(e.target.value));
  };

  function handleClose(): void {
    setOpen(false);
  }

  function handleClick(sequence: SequenceDto): void {
    setSelectedSequence(sequence);
    setOpen(true);
  }

  return (
    <StdView title={"Mina sekvenser"}>
      <Stack direction={"column"} spacing={3}>
        <Typography variant="h3" fontWeight={400}>
          Här visas dina skapade sekvenser, både privata och delade.
        </Typography>
        <Stack direction={"row"} spacing={1}>
          <OutlinedInput
            sx={{ width: "100%", borderRadius: "50px", height: "46px" }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            placeholder={"Sök sekvens"}
            onChange={onSearchEvent}
          />
          <SequenceFilterDrawer />
        </Stack>
        <Typography variant="h2">Fortsätt där du slutade</Typography>
        <Box>
          <Grid container spacing={{ xs: 1, sm: 3 }}>
            {sequenceDraft ? (
              sequenceDraft.map((sequence, index) => (
                <Grid item xs={12} sm={6} lg={4} xl={3} key={"sd" + index}>
                  <SequenceCard data={sequence} handleClick={handleClick} />
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Grid>
        </Box>
        <Typography variant="h2">Mina skapade sekvenser</Typography>
        <Box>
          <Grid container spacing={{ xs: 1, sm: 3 }}>
            {sequenceFinished ? (
              sequenceFinished.map((sequence, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
              
                  key={"sf" + index}
                >
                  <SequenceCard data={sequence} handleClick={handleClick} />
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Grid>
        </Box>
        <Typography variant="h2">Sekvenser delade med mig</Typography>
        <Box>
          <Grid container spacing={{ xs: 1, sm: 3 }}>
            {sharedSequences ? (
              sharedSequences.map((sequence, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
              
                  key={"sf" + index}
                >
                  <SequenceCard data={sequence} handleClick={handleClick} />
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Grid>
        </Box>
      </Stack>
      <Drawer
        open={open}
        anchor={"right"}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#F5F4F0",
          },
        }}
      >
        <SequenceInfoView
          sequence={selectedSequence}
          handleClose={handleClose}
        />
      </Drawer>
    </StdView>
  );
}
