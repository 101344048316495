import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export interface SequenceFilterState {
  searchString: string;
  sequenceLevels: number[];
  yogaForm: number[];
  noOfPositions: number[];
  page: number;
  pageSize: number;
}

const initialState: SequenceFilterState = {
  searchString: "",
  sequenceLevels: [],
  yogaForm: [],
  noOfPositions: [1, 200],
  page: 0,
  pageSize: 10,
};

export const sequenceFilterSlice = createSlice({
  name: "SequenceFilter",
  initialState,
  reducers: {
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSequenceLevels: (state, action: PayloadAction<number[]>) => {
      state.sequenceLevels = action.payload;
    },
    setYogaForm: (state, action: PayloadAction<number[]>) => {
      state.yogaForm = action.payload;
    },
    setNoOfPositions: (state, action: PayloadAction<number[]>) => {
      state.noOfPositions = action.payload;
    },
    resetFilter: (state, action: PayloadAction) => {
      state.searchString = "";
      state.sequenceLevels = [];
      state.yogaForm = [];
      state.noOfPositions = [1, 200];
      state.page = 0;
      state.pageSize = 10;
    },
  },
});

export const {
  setSearchString,
  setPage,
  setSequenceLevels,
  setYogaForm,
  setNoOfPositions,
  resetFilter
} = sequenceFilterSlice.actions;

export default sequenceFilterSlice.reducer;
export const sequenceFilterStateSelector = (
  state: RootState
): SequenceFilterState => state.sequenceFilter;
