import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { SequenceItemDto } from "api";
import React from "react";
import PauseIcon from "@mui/icons-material/Pause";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch } from "react-redux";
import { addSequencePause } from "store/slices/SequenceFormSlice";

export type SequencePauseListItemProps = {};

const SequencePauseListItem = ({}: SequencePauseListItemProps) => {
  const dispatch = useDispatch();

  return (
    <ListItem
      sx={{
        background: "#fff",
        borderRadius: "10px",
        boxShadow: 1,
        minHeight: "53px",
        "&.MuiListItem-root": { py: "5px" },
      }}
      secondaryAction={
        <IconButton onClick={() => dispatch(addSequencePause())}>
          <AddCircleIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar>
          <PauseIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={"Paus"} />
    </ListItem>
  );
};

export default SequencePauseListItem;
