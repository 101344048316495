import { AppBar, Divider, Toolbar } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { SequenceDto } from "api";
import DrawerCloseButton from "components/drawers/DrawerCloseButton";
import DrawerHeader from "components/drawers/DrawerHeader";
import SequenceCommentForm from "components/sequence/SequenceCommentForm";
import SequenceCommentList from "components/sequence/SequenceCommentList";
import React from "react";

export interface SequenceCommentViewProps {
  sequence: SequenceDto;
  handleClose?: () => void;
}

const SequenceCommentView = ({
  sequence,
  handleClose,
}: SequenceCommentViewProps) => {
  return (
    <>
      <DrawerHeader
        handleClose={() => handleClose!()}
        sx={{ backgroundColor: "#fff" }}
      />
      <Box sx={{ px: "20px", my: "10px" }}>
        <SequenceCommentForm sequenceId={sequence.sequenceId!} />
      </Box>
      <Divider sx={{ mb: "5px" }} />
      <Stack
        sx={{
          overflowY: "scroll",
          width: { xs: "100%", md: "500px" },
          px: "20px",
        }}
      >
        <SequenceCommentList
          sequenceId={sequence.sequenceId!}
          comments={sequence.comments!}
        />
      </Stack>
    </>
  );
};

export default SequenceCommentView;
