import React, { useEffect } from "react";
import { StdView } from "./StdView";
import { useDispatch, useSelector } from "react-redux";
import {
  contentfulStateSelector,
  loadVersionList,
} from "store/slices/contentfulSlice";
import { Stack } from "@mui/system";
import VersionAccordation from "components/accordions/VersionAccordation";

const VersionView = () => {
  const dispatch = useDispatch();
  const { versionList } = useSelector(contentfulStateSelector);

  useEffect(() => {
    dispatch<any>(loadVersionList());
  }, [dispatch]);

  return (
    <StdView title={"Versioner"} nosubscription>
      <Stack>
        {versionList &&
          versionList.listCollection.items.map((item, index) => {
            return (
              <Stack key={index} sx={{pb: '10px'}}>
                <VersionAccordation item={item} defaultExpanded={index==0} />
             
              </Stack>
            );
          })}
      </Stack>
    </StdView>
  );
};

export default VersionView;
