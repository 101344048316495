import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  setLevel,
  setMusicLink,
  setOtherInformation,
  setScript,
  setYogaForm,
  setTheme,
  sequenceFormStateSelector,
} from "store/slices/SequenceFormSlice";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  getLevelTitle,
  getYogaFormText,
  levelIds,
  yogaFormIds,
} from "utils/model";

export interface InformationViewProps {
  setIsValid: (isValid: boolean) => void;
}

const validationSchema = yup.object({
  yogaForm: yup.number().min(1, "Du måste välja en yogaform"),
  level: yup.number().min(1, "Du måste välja en nivå"),
  theme: yup.string().max(100, "Temat får max innehålla 100 tecken").nullable(),
  script: yup
    .string()
    .max(500, "Manuset får max innehålla 500 tecken")
    .nullable(),
  music: yup
    .string()
    .max(100, "Musiklänken får max innehålla 100 tecken")
    .nullable(),
  other: yup
    .string()
    .max(500, "Övrig information får max innehålla 500 tecken")
    .nullable(),
});

export default function InformationView({ setIsValid }: InformationViewProps) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { sequence } = useSelector(sequenceFormStateSelector);

  useEffect(() => {
    setIsValid(
      sequence?.yogaForm !== undefined &&
        sequence?.yogaForm!.toString() !== "0" &&
        sequence?.level !== undefined &&
        sequence?.level!.toString() !== "0"
    );
  }, [sequence]);

  const formik = useFormik({
    initialValues: {
      yogaForm: sequence!.yogaForm,
      level: sequence!.level,
      theme: sequence!.theme,
      script: sequence!.script,
      music: sequence!.musicLink,
      other: sequence!.otherInformation,
    },
    onSubmit: (values) => {
      console.log(JSON.stringify(values));
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
  });

  const newYogaForm = (yogaForm: number) => {
    dispatch(setYogaForm(yogaForm));
  };

  const newLevel = (level: number) => {
    dispatch(setLevel(level));
  };

  const newTheme = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTheme(e.target.value));
  };

  const newScript = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setScript(e.target.value));
  };

  const newMusicLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMusicLink(e.target.value));
  };

  const newOtherInformation = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setOtherInformation(e.target.value));
  };

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      sx={{ width: "100%" }}
    >
      <Typography variant="h1" sx={{ mb: "30px" }}>
        Lägg till information
      </Typography>
      <Grid container sx={{ mb: "30px" }} spacing={1}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: "bold" }}>Yogaform *</Typography>
          <FormControl sx={{ width: "100%" }}>
            <Select
              required
              placeholder="Välj Yogaform"
              id="yogaForm"
              name="yogaForm"
              value={
                formik.values.yogaForm
                  ? formik.values.yogaForm?.toString()
                  : "0"
              }
              onChange={(e: SelectChangeEvent) => {
                formik.handleChange(e);
                newYogaForm(parseInt(e.target.value));
              }}
              error={formik.touched.yogaForm && Boolean(formik.errors.yogaForm)}
              onBlur={formik.handleBlur}
            >
              <MenuItem value={0}>Välj Yogaform</MenuItem>
              {yogaFormIds.map((value, index) => (
                <MenuItem key={index} value={value}>
                  {getYogaFormText(value)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
              {formik.touched.yogaForm && formik.errors.yogaForm}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: "bold" }}>Nivå *</Typography>
          <FormControl sx={{ width: "100%" }}>
            <Select
              required
              placeholder="Välj nivå"
              id="level"
              name="level"
              value={
                formik.values.level !== undefined
                  ? formik.values.level?.toString()
                  : "0"
              }
              onChange={(e: SelectChangeEvent) => {
                formik.handleChange(e);
                newLevel(parseInt(e.target.value));
              }}
              error={formik.touched.level && Boolean(formik.errors.level)}
              onBlur={formik.handleBlur}
            >
              <MenuItem key={"n-0"} value={0}>
                Välj Nivå
              </MenuItem>
              {levelIds.map((value, index) => (
                <MenuItem value={value} key={"n-" + index + 1}>
                  {getLevelTitle(value)}
                </MenuItem>
              ))}
            </Select>

            <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
              {formik.touched.level && formik.errors.level}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: "bold", display: "inline" }}>
            Tema
          </Typography>
          <Typography sx={{ display: "inline" }}> (max 100 tecken)</Typography>
          <Typography sx={{ display: "inline" }}> (Valfritt)</Typography>
          <TextField
            id="theme"
            name="theme"
            placeholder="Fritext"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 100 }}
            value={formik.values.theme}
            onChange={formik.handleChange}
            onInput={newTheme}
            error={formik.touched.theme && Boolean(formik.errors.theme)}
            helperText={formik.touched.theme && formik.errors.theme}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography
              sx={{ fontWeight: "bold", display: "inline" }}
              onChange={newScript}
            >
              Manus
            </Typography>
            <Typography sx={{ display: "inline" }}>
              {" "}
              (max 500 tecken)
            </Typography>
            <Typography sx={{ display: "inline" }}> (Valfritt)</Typography>
          </Box>
          <TextField
            id="script"
            name="script"
            placeholder="Fritext"
            variant="outlined"
            inputProps={{ maxLength: 500 }}
            multiline
            rows={3}
            fullWidth
            value={formik.values.script}
            onChange={formik.handleChange}
            onInput={newScript}
            error={formik.touched.script && Boolean(formik.errors.script)}
            helperText={formik.touched.script && formik.errors.script}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography sx={{ fontWeight: "bold", display: "inline" }}>
              Musiklänk, t.ex till Spotifylista
            </Typography>
            <Typography sx={{ display: "inline" }}>
              {" "}
              (max 100 tecken)
            </Typography>
            <Typography sx={{ display: "inline" }}> (Valfritt)</Typography>
          </Box>

          <TextField
            id="music"
            name="music"
            inputProps={{ maxLength: 100 }}
            placeholder="Klista in länk här"
            variant="outlined"
            fullWidth
            value={formik.values.music}
            onChange={formik.handleChange}
            onInput={newMusicLink}
            error={formik.touched.music && Boolean(formik.errors.music)}
            helperText={formik.touched.music && formik.errors.music}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography sx={{ fontWeight: "bold", display: "inline" }}>
              Övrig information
            </Typography>
            <Typography sx={{ display: "inline" }}>
              {" "}
              (max 500 tecken)
            </Typography>
            <Typography sx={{ display: "inline" }}> (Valfritt)</Typography>
          </Box>
          <TextField
            id="other"
            name="other"
            placeholder="Fritext"
            variant="outlined"
            multiline
            fullWidth
            inputProps={{ maxLength: 500 }}
            rows={3}
            value={formik.values.other}
            onChange={formik.handleChange}
            onInput={newOtherInformation}
            error={formik.touched.other && Boolean(formik.errors.other)}
            helperText={formik.touched.other && formik.errors.other}
            onBlur={formik.handleBlur}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
