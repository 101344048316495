import FaqAccordation from "components/accordions/FaqAccordation";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  contentfulStateSelector,
  loadFaqList,
} from "store/slices/contentfulSlice";
import { StdView } from "views/OtherViews/StdView";

const FaqView = () => {
  const dispatch = useDispatch();
  const { faqList } = useSelector(contentfulStateSelector);

  useEffect(() => {
    dispatch<any>(loadFaqList());
  }, [dispatch]);

  return (
    <StdView title={"Frågor & svar"} nosubscription>
      {faqList &&
        faqList.listCollection.items.map((item, index) => {
          return <FaqAccordation item={item} key={index} />;
        })}
    </StdView>
  );
};

export default FaqView;
