import { Card, CardMedia, Grid } from "@mui/material";
import InspirationCard from "./InsprationCard";
import { useEffect, useState } from "react";
import { StdView } from "views/OtherViews/StdView";
import ContentfulService from "utils/ContentfulService";
import { IInspirationList, INSPIRATION_LIST_ID } from "utils/contentfulModel";

const PhilosophyListViewHeader = (): JSX.Element => {
  return (
    <Card sx={{mb: "15px", borderRadius:"10px" }}>
      <CardMedia
        sx={{ height: { xs: 200, sm: 336 }}}
        image="/Backgrounds/Philosophy.png"
        title="Filosofi"
      />
    </Card>
  );
};

export default function InspirationListView() {
  const [inspirationList, setInspirationList] = useState<IInspirationList | null>(null);

  const getInspirationList = () => {
    ContentfulService.post("/", {
      query:
      `query{inspirationList(id:"${INSPIRATION_LIST_ID}"){itemsCollection{items{title subTitle sys{id}}}}}`,
    })
      .then((data) => {
        console.log(data)
        setInspirationList(data.data.data.inspirationList as IInspirationList);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getInspirationList();
  }, []);

  return (
    <StdView title={"Inspiration"}>
      <Grid container spacing={2} sx={{ border: 0 }}>
        {inspirationList?.itemsCollection.items.map((inspiration, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            lg={4}
            sx={{
              maxWidth: {
                xs: "100%",
              },
            }}
          >
            <InspirationCard data={inspiration} key={index} />
          </Grid>
        ))}
      </Grid>
    </StdView>
  );
}
