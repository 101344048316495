import { createSelector } from "@reduxjs/toolkit";
import { positionFilterStateSelector } from "store/slices/positionFilterSlice";
import { positionStateSelector } from "store/slices/positionSlice";
import _ from "lodash";
import { PositionDto } from "api";

export const getPositionsByFilter = 
createSelector(
  [positionStateSelector, positionFilterStateSelector],
  (positionState, positionFilterState): PositionDto[] => {
    const filtered = positionState.positions.filter((position) => {
      return (
        (isInString(positionFilterState.searchString, position.sanskrit ?? "")|| 
        isInString(positionFilterState.searchString, position.swedish ?? ""))
        && (isInArray(position.level ?? 0, positionFilterState.levels)) 
        && (isArrayInArray(position.types!, positionFilterState.mainTypes)) 
        && (!position.private)
      );
    });

    if(positionFilterState.filtering == false){
      var filtredPositions = _.sortBy(
        filtered,
        "sanskrit"
      )
    }
    else {
      var filtredPositions = _.sortBy(
        filtered,
        "sanskrit"
      ).reverse()
    }

    return filtredPositions;
  }
);

const isSelectedValue = (value1: number, value2: number) => {
    if (value1 == 0) return true;
    return value1 === value2;
  };
  
  const isInString = (needle: string, haystack: string) => {
    if (needle.length < 1) return true;
    return haystack.match(new RegExp(needle, "i"));
  };
  
  const isInArray = (value: number, searchArray: number[]): boolean => {
    if (searchArray.length < 1) return true;
    return searchArray.includes(value);
  };
  
  const isArrayInArray = (value: number[], searchArray: number[]): boolean => {
    if (searchArray.length < 1) return true;
    return searchArray.some((r) => value.includes(r));
  };