import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, Button, Grid, Box, Stack, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  authStateSelector,
  getMeAsync,
  updateEmailAsync,
} from "store/slices/authSlice";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { LogInPaper } from "components/papers/LogInPaper";

const validationSchema = yup.object({
  currentEmail: yup
    .string()
    .email("Ange en gitltlig e-postadress")
    .required("Nuvarande e-postadress krävs"),
  newEmail: yup
    .string()
    .email("Ange en gitltlig e-postadress")
    .required("Ny e-postadress krävs")
    .notOneOf([yup.ref("currentEmail"), null], "Ange en ny E-postadres"),
  newEmailConfirm: yup
    .string()
    .test('newEmail', 
      'E-postadressen matchar inte', 
       function(emailConfirmation) { 
         return emailConfirmation === this.parent.newEmail; 
       }),
    
});

export default function ChangeEmailForm() {
  const dispatch = useDispatch();
  const authState = useSelector(authStateSelector);

  //Updates currentUser state
  useEffect(() => {
    dispatch<any>(getMeAsync());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      currentEmail: "",
      newEmail: "",
      newEmailConfirm: "",
    },
    onSubmit: (values, { resetForm }) => {
      dispatch<any>(updateEmailAsync(values.currentEmail, values.newEmail));
      resetForm({});
    },
    validationSchema: validationSchema,
  });

  return (
    <Stack>
      <Box>
        {authState.hasError && (
          <Alert severity="error">{authState.error}</Alert>
        )}
      </Box>
      <LogInPaper>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          sx={{ mt: "10px", mb: "20px" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Grid
                container
                spacing={4.5}
                sx={{ justifyContent: "center", mb: "25px" }}
              >
                <Grid item>
                  <TextField
                    required
                    id="currentEmail"
                    name="currentEmail"
                    label="Nuvarande e-postadress"
                    variant="standard"
                    value={formik.values.currentEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.currentEmail &&
                      Boolean(formik.errors.currentEmail)
                    }
                    helperText={
                      formik.touched.currentEmail && formik.errors.currentEmail
                    }
                    onBlur={formik.handleBlur}
                    sx={{ width: "250px" }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    required
                    id="newEmail"
                    name="newEmail"
                    label="Ny e-postadress"
                    variant="standard"
                    value={formik.values.newEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.newEmail && Boolean(formik.errors.newEmail)
                    }
                    helperText={
                      formik.touched.newEmail && formik.errors.newEmail
                    }
                    onBlur={formik.handleBlur}
                    sx={{ width: "250px" }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    required
                    id="newEmailConfirm"
                    name="newEmailConfirm"
                    label="Bekräfta ny e-postadress"
                    variant="standard"
                    value={formik.values.newEmailConfirm}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.newEmailConfirm &&
                      Boolean(formik.errors.newEmailConfirm)
                    }
                    helperText={
                      formik.touched.newEmailConfirm &&
                      formik.errors.newEmailConfirm
                    }
                    onBlur={formik.handleBlur}
                    sx={{ width: "250px" }}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12}>
                <Grid item xs={6} sx={{ textAlign: "left" }}>
                  <Button
                    variant="contained"
                    component={Link}
                    to={"/user/account"}
                  >
                    Avbryt
                  </Button>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Button type="submit" variant="contained" color="secondary">
                    Bekräfta
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </LogInPaper>
    </Stack>
  );
}
