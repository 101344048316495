import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  createNewSequence,
  sequenceFormStateSelector,
  updateExistingSequence,
} from "store/slices/SequenceFormSlice";
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';


export interface SequenceSaveAndCloseDialogProps {
  disabled: boolean;
}

const SequenceSaveAndCloseDialog = ({
  disabled,
}: SequenceSaveAndCloseDialogProps) => {
  const dispatch = useDispatch();

  const [isSaveAndCloseDialogOpen, setIsSaveAndCloseDialogOpen] =
    useState(false);

  const { sequence } = useSelector(sequenceFormStateSelector);

  const handleSaveAndCloseClick = async () => {
    setIsSaveAndCloseDialogOpen(false);

    var result = false;
    if (sequence!.sequenceId !== 0) {
      result = await dispatch<any>(updateExistingSequence());
    } else {
      result = await dispatch<any>(createNewSequence());
    }

    if (result) {
      setIsSaveAndCloseDialogOpen(true);
      console.log("SaveAndClose OK");
    }
  };

  const handleSaveAndCloseDialogClose = () => {
    setIsSaveAndCloseDialogOpen(false);
  };

  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={handleSaveAndCloseClick}
        sx={{ display: { xs: "flex", md: "none" } }}
      >
        <BookmarkAddedIcon />
      </IconButton>

      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        sx={{ borderRadius: "46px", display: { xs: "none", md: "flex" } }}
        onClick={() => handleSaveAndCloseClick()}
      >
        {sequence && sequence.sequenceId == 0
          ? "Spara utkast"
          : "Spara och stäng"}
      </Button>

      <Dialog
        open={isSaveAndCloseDialogOpen}
        onClose={handleSaveAndCloseDialogClose}
        
      >
        <DialogTitle>
          Sekvensen är sparad!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Du hittar den under Mina sekvenser.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              width: "150px",
              borderRadius: "46px",
              justifyContent: "center",
            }}
            component={Link}
            to={"/"}
            variant="contained"
            color="primary"
            onClick={handleSaveAndCloseDialogClose}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SequenceSaveAndCloseDialog;
