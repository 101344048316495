import { Alert, Button } from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppTextField from "../../components/forms/AppTextField";
import { authStateSelector, onForgotPassword } from "store/slices/authSlice";
import AuthCard from "components/cards/AuthCard";
import isEmailValidator from "validator/lib/isEmail";

const ForgotPasswordView = () => {
  const dispatch = useDispatch();
  const [showMessage, setShowMessage] = useState(false);
  const { isAuthenticating, hasError, error } = useSelector(authStateSelector);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Ange en giltlig e-postadress")
      .required("E-postadress krävs")
      .test("is-valid", "Ange en giltlig e-postadress", (value) =>
        value
          ? isEmailValidator(value)
          : new yup.ValidationError("Ange en giltlig e-postadress")
      ),
  });

  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        email: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (data, { setSubmitting, resetForm }) => {
        try {
          setShowMessage(false);
          var result = await dispatch<any>(onForgotPassword(data.email));
          if (result) {
            setShowMessage(true);
            resetForm();
          }
        } catch {}
      }}
    >
      {({ isSubmitting }) => (
        <Form style={{ textAlign: "left" }}>
          <AuthCard
            title="Glömt lösenord?"
            subTitle="Inga problem. Ange din e-postadress så skickar vi en
      återställningslänk."
            form={
              <>
                {hasError && (
                  <Alert severity="error" variant="outlined">
                    {error}
                  </Alert>
                )}
                {showMessage && (
                  <Alert severity="info" variant="outlined">
                    Ett mail med en återställningslänk har skickats till
                    e-postadressen du angav
                  </Alert>
                )}
                <Box
                  sx={{
                    mt: "5px",
                    mb: 3,
                  }}
                >
                  <AppTextField
                    color="primary"
                    required
                    placeholder="E-postadress"
                    name="email"
                    fullWidth
                  />
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  sx={{
                    fontSize: 16,
                  }}
                  type="submit"
                  fullWidth
                >
                  Skicka återställningslänk
                </Button>
              </>
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordView;
