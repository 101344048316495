import { Box, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getMeAsync, authStateSelector } from "store/slices/authSlice";
import { StdView } from "views/OtherViews/StdView";
import ChangePasswordForm from "components/forms/ChangePasswordForm";
import ProfileForm from "components/forms/ProfileForm";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import React from "react";
import AppInfoView from "components/common/AppInfoView";
import KeyIcon from "@mui/icons-material/Key";
import SubscriptionForm from "components/forms/SubscriptionForm";
import Iconify from "components/iconify";
import ShareIcon from "@mui/icons-material/Share";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: "20px" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function AccountView() {
  const dispatch = useDispatch();

  const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tabId = urlParams.get("tabid");

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(tabId != undefined)
      setValue(parseInt(tabId))
  }, []);

  useEffect(() => {
    dispatch<any>(getMeAsync());
  }, [dispatch]);

  const {currentUser} = useSelector(authStateSelector);

  return (
    <StdView title={"Mitt konto"} nosubscription>
      <AppInfoView />

      <Tabs
        value={value}
        onChange={handleChange}
        aria-label=""
        variant="scrollable"
        allowScrollButtonsMobile
        scrollButtons="auto"
        sx={{
          "& .MuiTabScrollButton-root": {
            width: "26px",
          },
          "& .MuiTab-root": {
            textTransform:"none"
          },
        }}
      >
        <Tab
          icon={<SettingsAccessibilityIcon />}
          iconPosition="start"
          label="Allmänt"
          {...a11yProps(0)}
        />
        <Tab
          icon={<Iconify icon="ion:card-outline" width={24} />}
          iconPosition="start"
          label="Prenumeration"
          {...a11yProps(1)}
        />
        <Tab
          icon={<KeyIcon />}
          iconPosition="start"
          label="Säkerhet"
          {...a11yProps(2)}
        />
       
      </Tabs>
      <TabPanel value={value} index={0}>
        <ProfileForm user={currentUser!} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SubscriptionForm />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ChangePasswordForm />
      </TabPanel>
      
    </StdView>
  );
}
