import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import React, { useCallback } from "react";

interface IProps extends DialogProps {
  onClose: VoidFunction;
  onCancelSubscription: VoidFunction;
}

const CancelSubscriptionDialog = ({
  onClose,
  onCancelSubscription,
  ...other
}: IProps) => {

  const handleCancelSubscription = useCallback(() => {
    onCancelSubscription();
    onClose();
  }, [onClose, onCancelSubscription]);

  return (
    <Dialog maxWidth="sm" onClose={onClose} {...other}>
      <DialogTitle> Avsluta? </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Vill du verkligen avsluta abonnemanget?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Avbryt
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCancelSubscription}
          autoFocus
        >
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelSubscriptionDialog;
