import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  setImage,
  sequenceFormStateSelector,
} from "store/slices/SequenceFormSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  loadSequenceImagesAsync,
  sequenceStateSelector,
} from "store/slices/SequenceSlice";

export interface CoverViewProps {
  setIsValid: (isValid: boolean) => void;
}

export default function CoverView({ setIsValid }: CoverViewProps) {
  const dispatch = useDispatch();

  const { images } = useSelector(sequenceStateSelector);
  const { sequence } = useSelector(sequenceFormStateSelector);

  useEffect(() => {
    dispatch<any>(loadSequenceImagesAsync());
  }, []);

  useEffect(() => {
    setIsValid(sequence?.image !== undefined);
  }, [sequence]);

  return (
    <Stack>
      <Typography variant="h1" sx={{ pb: 1 }}>
        Välj omslag
      </Typography>
      <Typography variant="h3" fontWeight={400} sx={{mb:"15px"}}>
        Välj en bild som stämmer överens med den känsla du vill att sekvensen
        ska förmedla.
      </Typography>

      <Grid container spacing={1}>
        {images.map((image, index) => (
          <Grid item xs={6} sm={3} md={3} key={index}>
            <Box
              sx={{
                border: 3,
                p: "2px",
                borderColor:
                  sequence?.image?.sequenceImageId === image.sequenceImageId
                    ? "#000"
                    : "#FFF",
                height: "170px",
              }}
            >
              <img
                src={`${"SequenceImages\\" + image.path!}`}
                width={"100%"}
                height={"100%"}
                alt=""
                onClick={() => dispatch(setImage(image))}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
