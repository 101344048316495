import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { PriceDto, ProductDto } from "api";
import React from "react";

interface SubscriptionCardProps {
  product: ProductDto;
  price: PriceDto;
  selectedPriceId: number;
  onSelect(priceId: number): void;
}
const SubscriptionCard = ({
  product,
  price,
  selectedPriceId,
  onSelect,
}: SubscriptionCardProps) => {
  const PerMonth = () => {
    return price.trialPeriodDays ? (
      <>
        <Typography variant="h5" component="div">
          {product.name}
        </Typography>
        <Typography variant="h3" component="div">
          {price.trialPeriodDays} dagar gratis
        </Typography>
        <Typography variant="body1" component="div">
          Därefter {price.unitAmount! / 100} kr per månad
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="h4" component="div">
          {product.name}
        </Typography>
        <Typography variant="h2" component="div">
          {price.unitAmount! / 100} kr per månad
        </Typography>
        <Typography variant="h5" component="div"></Typography>
      </>
    );
  };
  const PerYear = () => {
    return (
      <>
        <Typography variant="h4" component="div">
          {product.name}
        </Typography>
        <Typography variant="h2" component="div">
          {price.unitAmount! / 100} kr per år
        </Typography>
        <Typography variant="h5" component="div"></Typography>
      </>
    );
  };

  return (
    <Card
      sx={{
        border: selectedPriceId == price.id ? 3 : 1,
        m: selectedPriceId == price.id ? "0px" : "2px",
      }}
    >
      <CardContent
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {price.interval === "month" && <PerMonth />}
          {price.interval === "year" && <PerYear />}
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => onSelect(price.id!)}
        >
          Välj
        </Button>
      </CardActions>
    </Card>
  );
};

export default SubscriptionCard;
