import {
  Alert,
  Button,
  InputBase,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import {
  onUpdateCommentAsync,
  sequenceStateSelector,
} from "store/slices/SequenceSlice";
import { useSelector } from "react-redux";
import { SequenceCommentDto } from "api";
import { authStateSelector } from "store/slices/authSlice";

interface SequenceEditCommentFormProps {
  commentId: number;
  comment?: string;
  onSave?: () => void;
  onCancel?: () => void;
}

const SequenceEditCommentForm = ({
  commentId,
  comment,
  onSave,
  onCancel,
}: SequenceEditCommentFormProps) => {
  const dispatch = useDispatch();

  const { currentUser } = useSelector(authStateSelector);
  const { saving, hasError, error } = useSelector(sequenceStateSelector);

  const validationSchema = yup.object({
    comment: yup.string().required("Ange kommentar"),
  });

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={true}
      initialValues={{ comment: comment }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        const updateComment = {
          id: commentId,
          message: values.comment,
        } as SequenceCommentDto;
        try {
          console.log("update comment:", updateComment);
          const result = await dispatch<any>(
            onUpdateCommentAsync(updateComment)
          );
          if (result) {
            resetForm();
            if (onSave) onSave();
          }
        } catch (error) {
          console.log(error);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values, errors, handleChange, touched }) => (
        <Form noValidate autoComplete="off">
          {hasError && (
            <Alert severity="error" variant="outlined" sx={{ mb: "10px" }}>
              {error}
            </Alert>
          )}
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Stack direction={"column"} sx={{width:"100%"}}>
              <TextField
                sx={{ mb: 1}}
                fullWidth
                id="comment"
                value={values.comment}
                onChange={handleChange}
                multiline
                placeholder="Skriv kommentar"
                inputProps={{ "aria-label": "Kommentar", maxLength: 150 }}
              />

              <Stack direction={"row"} spacing={1} sx={{ border: 0, justifyContent:'flex-end' }}>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={onCancel}
                >
                  Avbryt
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Spara
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default SequenceEditCommentForm;
