import { Box, CardMedia, Container, Stack, Typography } from "@mui/material";
import * as React from "react";
import { PhilosophyInfoAccordion } from "components/accordions/PhilosophyInfoAccordion";
import { PhilosophyDto } from "api";

export interface PhilosophyInfoViewProps {
  philosophy?: PhilosophyDto;
}

export default function PhilosophyInfoView({
  philosophy,
}: PhilosophyInfoViewProps) {
  const bulletPoints = philosophy?.introduction!.split("\n").slice(0);

  return (
    <Stack sx={{ pt: "20px" }} spacing={2}>
      <Typography variant="h1">{philosophy?.cardTitel}</Typography>
      {bulletPoints?.map((intro) => (
        <Typography variant="h3" fontWeight={"normal"}>
          {intro}
        </Typography>
      ))}
      {philosophy?.imgPath && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img width={350} src={"/images/" + `${philosophy?.imgPath}`} alt="" />
        </Box>
      )}
      <Box>
        {philosophy?.philosophyContent?.map((data) => (
          <PhilosophyInfoAccordion title={data.titel} info={data.bodyText} />
        ))}
      </Box>
    </Stack>
  );
}
