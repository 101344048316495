import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Status } from "api";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  createNewSequence,
  sequenceFormStateSelector,
  setPrivate,
  setStatus,
  updateExistingSequence,
} from "store/slices/SequenceFormSlice";

export interface SequenceSaveDialogProps {
  handleSaveOK: () => void;
}

const SequenceSaveDialog = ({ handleSaveOK }: SequenceSaveDialogProps) => {
  const dispatch = useDispatch();

  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);

  const { sequence } = useSelector(sequenceFormStateSelector);

  const handleSaveDialogClose = () => {
    setIsSaveDialogOpen(false);
  };

  const handleSaveClick = async (isPrivate: boolean) => {
    setIsSaveDialogOpen(false);
    dispatch(setPrivate(isPrivate));
    dispatch(setStatus(2));

    var result = false;
    if (sequence!.sequenceId !== 0) {
      result = await dispatch<any>(updateExistingSequence());
    } else {
      result = await dispatch<any>(createNewSequence());
    }

    if (result) {
      handleSaveOK();
      console.log("Save from Save Dialog OK");
    }
  };

  const showSaveDialog = () => {
    console.log("SaveDialog");
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          sx={{ mr: "5px" }}
          onClick={() => handleSaveClick(sequence!.private!)}
        >
          Spara
        </Button>
        <Dialog open={isSaveDialogOpen} onClose={handleSaveDialogClose}>
          <DialogTitle>Sekvensen är sparad!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Du hittar den under Mina sekvenser.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                width: "150px",
                borderRadius: "46px",
                justifyContent: "center",
              }}
              component={Link}
              to={"/"}
              variant="contained"
              color="primary"
              onClick={handleSaveDialogClose}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const showShareOrPublicDialog = () => {
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          sx={{ borderRadius: "46px" }}
          onClick={() => setIsSaveDialogOpen(true)}
        >
          Spara
        </Button>
        <Dialog
          open={isSaveDialogOpen}
          onClose={handleSaveDialogClose}
          maxWidth={"xs"}
        >
          <DialogTitle>
            {"Vill du att andra ska kunna ta del av sekvensen?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Du kan när som helst ändra detta.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid container direction={"column"}>
              <Grid
                item
                xs={12}
                sx={{ justifyContent: "center", display: "flex" }}
              >
                <Button
                  sx={{
                    width: "208px",
                    borderRadius: "46px",
                    mb: "10px",
                    justifyContent: "center",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSaveClick(false)}
                >
                  Ja, spara och dela
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ justifyContent: "center", display: "flex" }}
              >
                <Button
                  sx={{
                    width: "208px",
                    borderRadius: "46px",
                    mb: "45px",
                    justifyContent: "center",
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleSaveClick(true)}
                >
                  Nej, spara privat
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <>
      {sequence &&
      (sequence!.sequenceId === 0 || sequence!.status === Status._1)
        ? showShareOrPublicDialog()
        : showSaveDialog()}
    </>
  );
};

export default SequenceSaveDialog;
