import {
  Avatar,
  Button,
  Drawer,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { PositionDto, SequenceItemType } from "api";
import DrawerContent from "components/drawers/DrawerContent";
import DrawerHeader from "components/drawers/DrawerHeader";
import React, { useState } from "react";
import InfoView from "views/CardViews/InfoView";
import PositionsInfoView from "views/CardViews/PositionsInfoView";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch } from "react-redux";
import { addSequenceItem } from "store/slices/SequenceFormSlice";

export interface PositionListItemProps {
  item: PositionDto;
}

const PositionListItem = ({ item }: PositionListItemProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  function handleClose(): void {
    setOpen(false);
  }

  const handleAddToSequence = () => {
    console.log("Add to sequence");
    dispatch(addSequenceItem(item))
    setOpen(false)
  };
  return (
    <>
      <ListItem
        sx={{
          background: "#fff",
          borderRadius: "10px",
          boxShadow: 1,
          "&.MuiListItem-root": { py: "5px" },
          "&:hover": { background: "rgb(235,235,235)" },
        }}
        secondaryAction={
          <IconButton onClick={() => dispatch(addSequenceItem(item))}>
            <AddCircleIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <Avatar
            alt={item.sanskrit!}
            src={"..\\PositionImages\\" + item.positionImages![0].path}
          />
        </ListItemAvatar>
        <ListItemText
          sx={{ cursor: "pointer" }}
          onClick={() => setOpen(true)}
          primary={item.sanskrit}
          secondary={item.swedish}
        />
      </ListItem>
      <Drawer
        open={open}
        anchor={"right"}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#FFF",
            minWidth: "300px",
          },
        }}
      >
        <DrawerHeader
          handleClose={handleClose}
          toolbar={
            <Button onClick={handleAddToSequence} variant="contained" color="secondary">Lägg till i sekvens</Button>
          }
        />
        <DrawerContent>
          <PositionsInfoView position={item} addToSequence />
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default PositionListItem;
