import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { SequenceDto, SequenceVideoDto } from "api";
import React from "react";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import ReactPlayer from "react-player";

export interface VideoDialogProps {
  video: SequenceVideoDto;
  index: number;
}

const VideoDialog = ({ video, index }: VideoDialogProps) => {
  console.log(video.url);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  return (
    <>
      <ListItemButton onClick={handleClickOpen}>
        <ListItemAvatar>
          <Avatar sx={{ width: 24, height: 24 }}>
            <LocalMoviesIcon sx={{ width: 16, height: 16 }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={video.title ? video.title : `Film ${index + 1}`} />
      </ListItemButton>

      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Box sx={{ height: 360 }}>
            <ReactPlayer
              url={video.url!}
              playing
              controls
              width="100%"
              height="100%"
            ></ReactPlayer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VideoDialog;
