import { Alert, Box, Grid, Stack, Typography } from "@mui/material";
import ReviewTextCard from "components/cards/ReviewTextCard";
import { useSelector } from "react-redux";
import {
  flipSequenceItem,
  sequenceFormStateSelector,
  setSequenceItemList,
} from "store/slices/SequenceFormSlice";
import ReviewNewSequenceCard from "components/cards/ReviewNewSequenceCard";
import { DropResult } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import { WarningPaper } from "components/papers/WarningPaper";
import { useDispatch } from "react-redux";
import SequenceDraggableList from "components/sequence/SequenceDraggableList";
import { reorder } from "utils/model";

export default function ReviewView() {
  const dispatch = useDispatch();

  const { sequence, hasError, error } = useSelector(sequenceFormStateSelector);

  const [update, SetUpdate] = useState(false);

  useEffect(() => {
    if (update) {
      //dispatch(updateSelectedPositions(list));
      SetUpdate(false);
    }
  }, [update]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(
      sequence!.sequenceItems!,
      source.index,
      destination.index
    );

    dispatch(setSequenceItemList(newItems));
  };

  function onToogleFlip(id: number): void {
    dispatch(flipSequenceItem(id));
  }

  return (
    <Stack sx={{}}>
      <Typography variant="h1" gutterBottom>
        Granska sekvens
      </Typography>

      {hasError && (
        <Box sx={{ mb: 3 }}>
          <WarningPaper>
            <Typography color="error">
              {error ?? "An unknown error occurred"}
            </Typography>
          </WarningPaper>
        </Box>
      )}

      <Box
        sx={{
          mt: "10px",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Alert
              severity="info"
              variant="filled"
              sx={{ color: "#fff", background: "#43594C", mb: "20px" }}
            >
              Du kan dra och flytta dina positioner i den ordning du vill ha
              dem. Vill du lägga till fler positioner kan du gå tillbaka till
              första steget genom att klicka på "Positioner" i menyn ovanför. Om
              du vill utföra en tillagd position åt andra hållet än det bilden
              visar (höger/vänster) kan du, efter du lagt till en position till
              "Min sekvens", spegelvända bilden genom att klicka på den.
            </Alert>

            <SequenceDraggableList
              items={sequence?.sequenceItems!}
              onDragEnd={onDragEnd}
              onToogleFlip={onToogleFlip}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <Grid>
                  {sequence && <ReviewNewSequenceCard sequence={sequence} />}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  mb: "142px",
                  mt: "30px",
                }}
              >
                <Grid>
                  <ReviewTextCard />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
