import { Card, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  checkPaymentStatusAsync,
  paymentStateSelector,
  setActiveStep,
} from "store/slices/paymentSlice";
import RichTextField from "../RichTextField";
import {
  contentfulStateSelector,
  getThanksForSubscriptionText,
} from "store/slices/contentfulSlice";

interface PaymentSummaryProps {}

const PaymentSummary = ({}: PaymentSummaryProps) => {
  const dispatch = useDispatch();

  const { thanksForSubscriptionText } = useSelector(contentfulStateSelector);
  const { paymentSession, sessionId } = useSelector(paymentStateSelector);

  useEffect(() => {
    dispatch<any>(checkPaymentStatusAsync(sessionId));
    dispatch<any>(getThanksForSubscriptionText());
  }, [dispatch, sessionId]);

  if (paymentSession?.status === "open") {
    dispatch(setActiveStep(1));
    return <Navigate to="/subscription" />;
  }

  console.log(thanksForSubscriptionText);
  return (
    <Container maxWidth={"lg"}>
      {paymentSession?.status === "complete" && (
        <Card>
          <Stack sx={{p:"20px"}}>
            <Typography variant="h2" gutterBottom>
              {thanksForSubscriptionText?.title}
            </Typography>
            {thanksForSubscriptionText && (
              <RichTextField document={thanksForSubscriptionText?.text.json!} />
            )}
          </Stack>
        </Card>
      )}
      {paymentSession?.status !== "complete" && (
        <Stack>
          <Typography variant="h1">Väntar...</Typography>
          <Typography variant="h1">{paymentSession?.status}</Typography>
        </Stack>
      )}
    </Container>
  );
};

export default PaymentSummary;
