import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { InfoAccordion } from "components/accordions/InfoAccordion";
import React, { useEffect, useState } from "react";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import RadarIcon from "@mui/icons-material/Radar";
import PositionSlideshow from "../../components/position/PositionSlideshow";
import { PositionDto } from "api";
import { PrePositionsAccordion } from "components/accordions/PrePositionsAccordion";
import { PostPositionsAccordion } from "components/accordions/PostPositionsAccordion";
import Grid from "@mui/material/Unstable_Grid2";
import { getLevelTitle, getMainTypesText } from "utils/model";
import { useDispatch } from "react-redux";
import {
  loadPositionAsync,
  setSelectedPosition,
} from "store/slices/positionSlice";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";

export interface PositionsInfoViewProp {
  position?: PositionDto | null;
  refreshData?: boolean;
  addToSequence?: boolean;
}

export default function PositionsInfoView({
  position,
  refreshData,
  addToSequence
}: PositionsInfoViewProp) {
  const dispatch = useDispatch();
  const [positionData, setPositionData] = useState<PositionDto | null>(null);

  useEffect(() => {
    setPositionData(position!);
    if (refreshData) refreshPositionData();
  }, [position]);

  const refreshPositionData = async () => {
    const data = await dispatch<any>(loadPositionAsync(position!.positionId!));
    setPositionData(data)
  };

  return (
    <Box sx={{ maxWidth: 1000 }}>
      <Stack spacing={0} >
        <Grid container spacing={3} >
          <Grid xs={12} sm={8}>
            <Typography fontSize={"2rem"} sx={{ pb: "5px" }}>
              {positionData?.sanskrit}
            </Typography>
            <Typography sx={{ pb: "10px" }}>
              {positionData?.english} / {positionData?.swedish}
            </Typography>

            <Box
              sx={{
                backgroundColor: "#FFF",
                borderRadius: "10px",
                p: 2,
                mt: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid xs={12} sm={4}>
                  <Stack direction={"row"}>
                    <SignalCellularAltIcon sx={{ pr: "10px" }} />
                    <Typography sx={{ fontWeight: "bold" }}>Nivå</Typography>
                  </Stack>
                </Grid>
                <Grid xs={12} sm={8}>
                  <Typography>{getLevelTitle(positionData?.level!)}</Typography>
                </Grid>

                <Grid xs={12} sm={4}>
                  <Stack direction={"row"}>
                    <SelfImprovementIcon sx={{ pr: "10px" }} />
                    <Typography sx={{ fontWeight: "bold" }}>Typ</Typography>
                  </Stack>
                </Grid>
                <Grid xs={12} sm={8}>
                  <Typography>
                    {positionData?.types?.map(
                      (item, index) =>
                        (index > 0 ? ", " : "") + getMainTypesText(item)
                    )}
                  </Typography>
                </Grid>
                <Grid xs={12} sm={4}>
                  <Stack direction={"row"}>
                    <RadarIcon sx={{ pr: "10px" }} />
                    <Typography sx={{ fontWeight: "bold" }}>
                      Huvudsyfte
                    </Typography>
                  </Stack>
                </Grid>
                <Grid xs={12} sm={8}>
                  <Typography>{positionData?.mainPurpose}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid xs={12} sm={4}>
            <PositionSlideshow data={positionData} />
          </Grid>
        </Grid>

        <Stack spacing={1}>
          {(positionData?.prePose1 || positionData?.prePose2 || positionData?.prePose3) && (
            <PrePositionsAccordion
              title={"FÖREGÅENDE POSITIONER"}
              data={positionData}
              addToSequence={addToSequence}
              refreshData
            />
          )}

          {(positionData?.postPose1 ||
            positionData?.postPose2 ||
            positionData?.postPose3) && (
            <PostPositionsAccordion
              title={"EFTERFÖLJANDE POSITIONER"}
              data={positionData}
              addToSequence={addToSequence}
              refreshData
            />
          )}

          {positionData?.benefitsText! && (
            <InfoAccordion
              title={"POSITIVA EFFEKTER"}
              text={positionData?.benefitsText!}
            />
          )}
          {positionData?.assistanceText! && (
            <InfoAccordion
              title={"ASSISTERING"}
              text={positionData?.assistanceText!}
            />
          )}
          {positionData?.payAttentionToText! && (
            <InfoAccordion
              title={"UPPMÄRKSAMMA"}
              text={positionData?.payAttentionToText!}
            />
          )}
          {positionData?.variantsText! && (
            <InfoAccordion title={"VARIANTER"} text={positionData?.variantsText!} />
          )}
          {positionData?.instructionsText! && (
            <InfoAccordion
              title={"INSTRUKTION TILL ELEVER"}
              text={positionData?.instructionsText!}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
